import Service, { service } from '@ember/service';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';

export default class RewardProgramsService extends Service {
  @service metrics;
  @service sesh;
  @service store;

  @action
  async enableRewardProgram(rewardProgram, params) {
    let enabledProgram = await rewardProgram.enable(params);

    if (!this.sesh.hasEverEnabledRewarding) {
      this.metrics.trackEvent('GoogleTagManager', {
        event: 'StartedRewarding',
        first_launched: enabledProgram.type,
      });
    }

    return enabledProgram;
  }

  installRewardProgram = task(async (rewardProgramDefinition) => {
    let {
      sesh: { account },
    } = this;

    let rewardProgram = this.store.createRecord('reward-program', {
      type: rewardProgramDefinition.get('reward_program_type'),
      account,
    });

    await rewardProgram.save();
    await account.reload();
  });
}
