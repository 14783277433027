import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { timeout, task } from 'ember-concurrency';
import {
  Intercom,
  update as sdkUpdate,
  startTour as sdkStartTour,
  showNewMessage as sdkShowNewMessage,
} from '@intercom/messenger-js-sdk';
import { storageFor } from 'ember-local-storage';

export default class IntercomService extends Service {
  @service config;
  @service sesh;
  @service router;

  @storageFor('sweet-tooth-session') localStorage;

  @tracked isBooted = false;
  @tracked shouldShowWidget = true;

  startTour(tourId) {
    sdkStartTour(tourId);
  }

  showNewMessage(message) {
    sdkShowNewMessage(message);
  }

  async showWidget() {
    await this._setWidgetVisibility(true);
  }

  async hideWidget() {
    await this._setWidgetVisibility(false);
  }

  async _setWidgetVisibility(shouldShow) {
    if (this._isIntercomDisabled()) {
      return;
    }

    this.shouldShowWidget = shouldShow;

    if (!this.boot.isRunning && !this.isBooted && shouldShow) {
      await this.boot.perform();
    } else if (this.isBooted) {
      sdkUpdate({ hide_default_launcher: !shouldShow });
    }
  }

  _isIntercomDisabled() {
    return (
      this.localStorage.get('isAdmin') || !this.config.get('intercom.enabled')
    );
  }

  boot = task({ drop: true }, async () => {
    if (this._isIntercomDisabled()) {
      return;
    }

    const options = {
      app_id: this.config.get('intercom.appId'),
      hide_default_launcher: !this.shouldShowWidget,
    };

    const user = this.sesh.user;
    if (user) {
      options.user_id = user.id;
      options.user_hash = user.intercomHash;
      options.name = user.name;
      options.email = user.email;
    }

    Intercom(options);

    let intercomFrame;
    while (!intercomFrame) {
      intercomFrame = document.querySelector('iframe#intercom-frame');
      if (!intercomFrame) {
        await timeout(500);
      }
    }

    this.isBooted = true;
  });
}
