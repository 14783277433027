/* import __COLOCATED_TEMPLATE__ from './transition-to-launcher.hbs'; */
import { tagName } from '@ember-decorators/component';
import { service } from '@ember/service';
import Component from '@ember/component';

@tagName('')
export default class TransitionToLauncher extends Component {
  @service
  featureRollouts;
}
