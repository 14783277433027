/* import __COLOCATED_TEMPLATE__ from './placement.hbs'; */
import { tagName } from '@ember-decorators/component';
import { action, computed } from '@ember/object';
import { service } from '@ember/service';
import { equal } from '@ember/object/computed';
import Component from '@ember/component';

@tagName('')
export default class Placement extends Component {
  @service
  sesh;

  /**
   * Action fired when a property is updated.
   *
   * @property {onChange}
   * @type {Function}
   * @default no-op
   * @public
   */
  onChange() {}

  @equal('selectedTab', 'desktop')
  isDesktopMode;

  @(computed('isDesktopMode', 'launcher.isVisibleOnMobile').readOnly())
  get isMobileModeAndMobileLauncherDisabled() {
    return !this.isDesktopMode && !this.get('launcher.isVisibleOnMobile');
  }

  @action
  updateMargin(displaySettingChangeset, customizablePropertyPath, newValue) {
    this.onChange(
      'displaySetting',
      displaySettingChangeset,
      customizablePropertyPath,
      `${newValue}px`
    );
  }

  @action
  updatePlacementProperty(displaySettingChangeset, value) {
    if (this.sesh.isOnReducedFreeBranding) {
      // Under reduced free branding, launcher position on mobile
      // is always pinned to the same value as on desktop. This is
      // handled by Core so we only need to update the desktop
      // position here.
      this.onChange(
        'displaySetting',
        displaySettingChangeset,
        'smileUiDesktopPosition',
        value
      );

      return;
    }

    const devicePosition = this.isDesktopMode
      ? 'smileUiDesktopPosition'
      : 'customizableSmileUiMobilePosition';

    this.onChange(
      'displaySetting',
      displaySettingChangeset,
      devicePosition,
      value
    );
  }
}
