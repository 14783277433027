/* eslint-disable ember/no-new-mixins */
import Mixin from '@ember/object/mixin';
import { computed } from '@ember/object';
import { service } from '@ember/service';
import { isNone, typeOf, isEmpty } from '@ember/utils';

// TODO don't think this is used in a long time...drop this and `error` model?
export default Mixin.create({
  store: service(),

  /**
   * Stores an ember data error model
   */
  error: null,

  /**
   * This can be called from any controller to add an error. A string
   * or jquery promise error can be used.
   *
   * Usage:
   *
   * this.addError(promiseError);
   * this.addError("My custom error message")
   */
  addError(error) {
    var errorData = {};

    if (isNone(error)) {
      error = 'No error message received';
    }

    if (typeof error === 'string') {
      errorData.customErrorMessage = error;
    } else if (typeOf(error) === 'error' && !isEmpty(error.errors)) {
      errorData.customErrorMessage = Array.from(
        new Set(error.errors.map((err) => err.detail.error.message))
      ).join('<br>');
    } else {
      // Assuming promise error response
      errorData = error;
    }

    var errorModel = this.store.createRecord('error', errorData);
    this.set('error', errorModel);
  },

  /**
   * Use this in hbs templates to detect whether an error
   * has occurred.
   *
   * Usage:
   *
   * {{#if hasError}}
   *   // Render error html
   * {{/if}}
   */
  hasError: computed('error', function () {
    return !isNone(this.get('error'));
  }),

  /**
   * Returns a string error message. This is the function
   * you should access if you need a customer facing message
   * to be displayed.
   * By setting the error through addError() and accessing the message
   * through this function, all parsing is done to make sure this message
   * is human readable.
   */
  errorMessage: computed('error', function () {
    var error = this.get('error');

    if (isNone(error)) {
      return '';
    }

    // This is a standard ST error response
    if (error.get('constructor.modelName') === 'error') {
      return error.get('message');
    }

    // Assume error is a jquery ajax error
    return error.status + ' ' + error.statusText;
  }),
});
