import { service } from '@ember/service';
import Route from '@ember/routing/route';
import LoadingWindowMixin from 'smile-admin/mixins/routes/loading-window';

export default class UnauthenticatedRoute extends Route.extend(
  LoadingWindowMixin
) {
  @service session;
  @service intercom;

  beforeModel() {
    this.session.prohibitAuthentication('home');

    super.beforeModel(...arguments);
  }

  activate() {
    super.activate(...arguments);

    this.intercom.showWidget();
  }
}
