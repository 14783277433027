import Helper from '@ember/component/helper';
import { isBlank } from '@ember/utils';
import HandlebarsCompiler from 'smile-admin/objects/handlebars-compiler';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

/**
 * Helper to compile Handlebar templates on the fly.
 *
 * @param {String} template           The Handlebars template
 * @param {Object} templateVariables  The variables required for the template.
 * @param {Ember.Object} context      The object that contains the variables.
 *
 *  Usage:
 *    {{render-handlebars template templateVariables context}}
 */
export default class RenderHandlebars extends Helper {
  @service sesh;

  @tracked compiler = null;

  compute([template, templateVariables, context, defaultText]) {
    if (isBlank(template)) {
      if (!isBlank(defaultText)) {
        return defaultText;
      }

      return;
    }

    if (isBlank(context) || isBlank(templateVariables)) {
      return template;
    }

    this.compiler = HandlebarsCompiler.create({
      template,
      templateVariables,
      content: context,
      sesh: this.sesh,
      onChange: this.recompute.bind(this),
    });

    const compiledTemplate = this.compiler.compiledTemplate;
    if (isBlank(compiledTemplate)) {
      return defaultText;
    }

    return compiledTemplate;
  }
}
