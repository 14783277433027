<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" ...attributes>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 6a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm5.228 2.362a4 4 0 1 0-6.457 0A7.003 7.003 0 0 0 0 15v1a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-1a7.003 7.003 0 0 0-4.772-6.638zM8 10H7a5 5 0 0 0-5 5v1h12v-1a5 5 0 0 0-5-5H8zM18 5a1 1 0 1 0-2 0v1h-1a1 1 0 1 0 0 2h1v1a1 1 0 1 0 2 0V8h1a1 1 0 1 0 0-2h-1V5z"
      fill="#637381"
    />
  </svg>
</template>
