import Model, { belongsTo, attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { or } from '@ember/object/computed';
import { memberAction } from 'ember-api-actions';
import { serializeAndPush } from 'smile-admin/utils/models/serialize-and-push';
import { badgeStatusForState } from 'smile-admin/helpers/badge-status-for-state';
import { formatCurrency } from 'smile-admin/helpers/format-currency';
import { service } from '@ember/service';

export default Model.extend({
  sesh: service(),

  account_id: attr('number'),
  completed_at: attr('utc'),
  createdAt: attr('utc'),
  updated_at: attr('utc'),

  // New referral fields
  friendEmail: attr('string'),
  state: attr('string'),
  description: attr('string'),
  risks: attr('array'),

  advocateCustomer: belongsTo('customer', { async: false, inverse: null }),
  friendCustomer: belongsTo('customer', { async: false, inverse: null }),
  goalOrder: belongsTo('order', { async: false, inverse: null }),

  advocateRewardFulfillment: belongsTo('reward-fulfillment', {
    async: false,
    inverse: null,
  }),
  friendTrackingRewardFulfillment: belongsTo('reward-fulfillment', {
    async: false,
    inverse: null,
  }),

  statusBadgeType: computed('state', function () {
    return badgeStatusForState(this.state);
  }).readOnly(),

  friendDisplayName: or(
    'friendCustomer.fullName',
    'friendCustomer.email',
    'friendEmail'
  ).readOnly(),

  goalOrderDisplayAmount: computed('goalOrder.grand_total', function () {
    let { goalOrder } = this;

    return goalOrder
      ? formatCurrency(goalOrder.grand_total, this.sesh)
      : 'No order placed';
  }).readOnly(),

  cancel: memberAction({
    path: 'cancel',
    type: 'post',
    after: serializeAndPush,
  }),

  unblock: memberAction({
    path: 'unblock',
    type: 'post',
  }),
});
