import ObjectProxy from '@ember/object/proxy';
import { assert } from '@ember/debug';
import { computed } from '@ember/object';
import { typeOf } from '@ember/utils';
import Handlebars from 'handlebars';
import { service } from '@ember/service';

/**
 * Handlebars template compiler for our customizable template properties.
 *
 * Usage:
 *      HandlebarsCompiler.create({
 *        template,
 *        templateVariables,
 *        content: compilerContext,
 *      }).get('compiledTemplate');
 *
 * @param {String}  template           The actual Handlebars template.
 * @param {Object}  templateVariables  The template variables.
 * @param {Object}  content            The context object from which the
 *                                    variables are extracted for the compilation.
 * @param {Function} onChange          Optional onChange callback
 */
export default ObjectProxy.extend({
  sesh: service(),

  /**
   * The Handlebars template variable that should be compiled.
   */
  template: null,

  templateVariables: null,

  onChange: null,

  // TODO recheck the template variables and standardize the format used.
  dependentVariablesKeys: computed('templateVariables', function () {
    let templateVariables = this.get('templateVariables');
    // If it's an array, return them right away
    if (typeOf(templateVariables) === 'array') {
      return templateVariables;
    }

    if (typeOf(templateVariables) === 'string') {
      templateVariables = this.get(templateVariables);
    }

    return this.normalizeVariableKeys(templateVariables);
  }),

  normalizeVariableKeys(vars) {
    if (Object.keys(vars).length === 0) {
      return [];
    }

    let keys = Object.keys(vars);
    return Array.from(
      new Set(
        keys.map((key) => {
          let parts = key.split('.');
          if (parts.length === 1) {
            return key;
          }

          return parts[0];
        })
      )
    );
  },

  compiledTemplate: computed('template', 'needsRecompilation', function () {
    return this.compileTemplate();
  }),

  defaultContext: computed(function () {
    return {
      points_label_singular: this.get('sesh.pointsLabelSingular'),
      points_label_plural: this.get('sesh.pointsLabelPlural'),
    };
  }),

  /**
   * If you have a reference to this compiler, you can easily call it with
   * a `options` object and pass in any data that you want the template to
   * be recompiled with.
   */
  compileTemplate(options) {
    let compiledTemplate = Handlebars.compile(this.get('template'));
    let dependentVariablesKeys = this.get('dependentVariablesKeys');
    let data = {
      ...this.getProperties(dependentVariablesKeys),
      ...this.defaultContext,
      ...options,
    };

    try {
      return compiledTemplate(data);
    } catch (err) {
      // eslint-ignore no-empty
    }

    // If content fails to compile, just return the raw template
    return this.get('template');
  },

  initCompiler() {
    let dependentVariablesKeys = this.get('dependentVariablesKeys');
    let context = this.get('content');

    // We observe properties that are required for the template compilation.
    // to make sure we recompile when upstream changes
    dependentVariablesKeys.forEach((key) => {
      context.addObserver(key, this, this.notifyChanges);
    });
  },

  notifyChanges() {
    this.notifyPropertyChange('needsRecompilation');
    const onChange = this.get('onChange');
    if (onChange && typeof onChange === 'function') {
      onChange();
    }
  },

  init() {
    this._super(...arguments);

    assert(
      '[objects/handlebars-compiler] Missing required `template` param!',
      this.get('template')
    );
    assert(
      '[objects/handlebars-compiler] Missing required `templateVariables` param!',
      this.get('templateVariables')
    );
    assert(
      '[objects/handlebars-compiler] Missing required `content` param!',
      this.get('content')
    );

    this.initCompiler();
  },

  willDestroy() {
    this._super(...arguments);

    // Clean up
    let dependentVariablesKeys = this.get('dependentVariablesKeys');
    let context = this.get('content');

    dependentVariablesKeys.forEach((key) => {
      context.removeObserver(key, this, this.notifyChanges);
    });
  },
});
