/* import __COLOCATED_TEMPLATE__ from './add-points-program-type.hbs'; */
import Component from '@ember/component';
import { action } from '@ember/object';
import { tagName } from '@ember-decorators/component';
import { storageFor } from 'ember-local-storage';
import { service } from '@ember/service';

@tagName('')
export default class AddProgramTypePointsComponent extends Component {
  @service sesh;
  @service shopify;

  shouldRenderFeedbackForm = false;

  @storageFor('sweet-tooth-session')
  localStorage;

  get shouldRender() {
    return (
      !this.localStorage.get('hasDismissedAddProgramTypePointsCard') &&
      !this.shopify.isEmbedded
    );
  }

  @action
  dismissCard() {
    let dismiss = this.onDismiss;

    if (dismiss) {
      dismiss('hasDismissedAddProgramTypePointsCard');
    }
  }
}
