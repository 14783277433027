import Features from 'ember-feature-flags/services/features';
import { datadogRum } from '@datadog/browser-rum';
import config from 'smile-admin/config/environment';

export default class FeatureRollouts extends Features {
  enable(flag) {
    datadogRum.addFeatureFlagEvaluation(flag, true);
    super.enable(...arguments);
  }

  disable(flag) {
    datadogRum.addFeatureFlagEvaluation(flag, false);
    super.disable(...arguments);
  }

  // Overwrites addon to fix a error in the console.
  // The error is:
  // Assertion Failed: You attempted to access the `LOG_FEATURE_FLAG_MISS` property (of <smile-admin@service:config::ember372>).
  // Since Ember 3.1, this is usually fine as you no longer need to use `.get()`
  // to access computed properties. However, in this case, the object in question
  // is a special kind of Ember object (a proxy). Therefore, it is still necessary
  // to use `.get('LOG_FEATURE_FLAG_MISS')` in this case.
  _logFeatureFlagMissEnabled() {
    return !!config.LOG_FEATURE_FLAG_MISS;
  }

  // Overwrites addon to have appropriate logging messsage
  _logFeatureFlagMiss(feature) {
    // eslint-disable-next-line no-console
    if (console && console.info) {
      // eslint-disable-next-line no-console
      console.info('Rollout flag off:', feature);
    }
  }
}
