import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { storageFor } from 'ember-local-storage';

export default class LogoutRoute extends Route {
  @service session;
  @storageFor('sweet-tooth-session') localStorage;

  beforeModel() {
    super.beforeModel(...arguments);

    this.localStorage.set('isAdmin', false);
    this.session.invalidate();
  }
}
