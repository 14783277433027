import setupDeprecationWorkflow from 'ember-cli-deprecation-workflow';

setupDeprecationWorkflow({
  workflow: [
    { handler: 'silence', matchId: 'component-template-resolving' },
    { handler: 'silence', matchId: 'ember-data:deprecate-legacy-imports' },
    { handler: 'silence', matchId: 'ember-data:deprecate-non-strict-types' },
    {
      handler: 'silence',
      matchId: 'ember-data:deprecate-store-extends-ember-object',
    },
    { handler: 'silence', matchId: 'ember-feature-flags.unknown-property' },
    {
      handler: 'silence',
      matchId: 'ember-lifeline-deprecated-addeventlistener',
    },
    {
      handler: 'silence',
      matchId: 'ember-lifeline-deprecated-context-bound-event-listeners-mixin',
    },
    {
      handler: 'silence',
      matchId: 'ember-lifeline-deprecated-context-bound-tasks-mixin',
    },
    { handler: 'silence', matchId: 'ember-polaris.polaris-card.class-arg' },
    {
      handler: 'silence',
      matchId: 'ember-polaris.polaris-footer-help.class-arg',
    },
    {
      handler: 'silence',
      matchId: 'ember-polaris.polaris-form-layout-item.class-arg',
    },
    { handler: 'silence', matchId: 'ember-polaris.polaris-icon.class-arg' },
    { handler: 'silence', matchId: 'ember-polaris.polaris-stack.class-arg' },
    {
      handler: 'silence',
      matchId: 'ember-polaris.polaris-stack-item.class-arg',
    },
    { handler: 'throw', matchId: 'smile-admin.deprecate-old-ember-data-api' },
    { handler: 'silence', matchId: 'smile-admin.deprecate-data-loader' },
    { handler: 'silence', matchId: 'template-action' },
    { handler: 'silence', matchId: 'warp-drive.ember-inflector' },
  ],
});
