/**
 * Extracted from ember-fetch:
 * https://github.com/ember-cli/ember-fetch/blob/40867376fdb736b5d6dd7df58a27fbc3cddb09a5/addon/utils/serialize-query-params.ts
 *
 * This code has been extracted from ember-fetch because it is the only part of the package we are using.
 * The original ember-fetch library has not been updated since 2022 and contains known security vulnerabilities.
 * To mitigate these issues and ensure our application's security, we have directly integrated this portion
 * of the code into our project.
 */

function isPlainObject(obj) {
  return Object.prototype.toString.call(obj) === '[object Object]';
}

const RBRACKET = /\[\]$/;

/**
 * Helper function that turns the data/body of a request into a query param string.
 * This is directly copied from jQuery.param.
 */
function serializeQueryParams(queryParamsObject) {
  var s = [];

  function buildParams(prefix, obj) {
    var i, len, key;

    if (prefix) {
      if (Array.isArray(obj)) {
        for (i = 0, len = obj.length; i < len; i++) {
          if (RBRACKET.test(prefix)) {
            add(s, prefix, obj[i]);
          } else {
            buildParams(
              prefix + '[' + (typeof obj[i] === 'object' ? i : '') + ']',
              obj[i]
            );
          }
        }
      } else if (isPlainObject(obj)) {
        for (key in obj) {
          buildParams(prefix + '[' + key + ']', obj[key]);
        }
      } else {
        add(s, prefix, obj);
      }
    } else if (Array.isArray(obj)) {
      for (i = 0, len = obj.length; i < len; i++) {
        add(s, obj[i].name, obj[i].value);
      }
    } else {
      for (key in obj) {
        buildParams(key, obj[key]);
      }
    }
    return s;
  }

  return buildParams('', queryParamsObject).join('&').replace(/%20/g, '+');
}

/**
 * Part of the `serializeQueryParams` helper function.
 */
function add(s, k, v) {
  // Strip out keys with undefined value and replace null values with
  // empty strings (mimics jQuery.ajax)
  if (v === undefined) {
    return;
  } else if (v === null) {
    v = '';
  }

  v = typeof v === 'function' ? v() : v;
  s[s.length] = encodeURIComponent(k) + '=' + encodeURIComponent(v);
}

export default serializeQueryParams;
