/* import __COLOCATED_TEMPLATE__ from './new-feature-release.hbs'; */
import { tagName } from '@ember-decorators/component';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { readOnly } from '@ember/object/computed';
import Component from '@ember/component';

@tagName('')
export default class NewFeatureRelease extends Component {
  @service
  store;

  shouldRender = true;

  @readOnly('homeCard.featureRelease')
  featureRelease;

  dismissFeatureRelease = task(
    {
      drop: true,
    },
    async (featureRelease) => {
      this.set('shouldRender', false);

      await featureRelease.dismiss();
    }
  );
}
