/* import __COLOCATED_TEMPLATE__ from './card.hbs'; */
import Component from '@glimmer/component';
import { copy } from 'ember-copy';
import { smileDefaultChartOpts } from 'smile-admin/helpers/analytics-charts';
import { formatNumber } from 'smile-admin/helpers/format-number';
import { formatCurrency } from 'smile-admin/helpers/format-currency';
import { service } from '@ember/service';

export default class HomeDashboardCard extends Component {
  @service sesh;

  get chartOptions() {
    let defaults = copy(smileDefaultChartOpts, true);
    let opts = Object.assign({}, defaults, {
      layout: {
        padding: {
          left: -10,
          bottom: -5,
        },
      },
    });

    return opts;
  }

  get totalFormatted() {
    if (this.args.data.format === 'currency') {
      return formatCurrency(this.args.data.total, this.sesh, '(0.0a)');
    }
    return formatNumber(this.args.data.total, { format: '0.[0]a' });
  }

  get chartData() {
    return [
      {
        data: this.args.data.chartValues,
      },
    ];
  }
}
