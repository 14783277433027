import SimpleAuthSessionService from 'ember-simple-auth/services/session';
import { service } from '@ember/service';

export default class SessionService extends SimpleAuthSessionService {
  @service router;

  /**
   * Overwrite the handle authentication method, to pass our `routeAfterAuthentication` as 'home'
   * instead of the default 'index'.
   */
  handleAuthentication() {
    super.handleAuthentication('home');
  }
}
