/* import __COLOCATED_TEMPLATE__ from './adjust-points-balance.hbs'; */
import { classNames } from '@ember-decorators/component';
import { action, computed } from '@ember/object';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { readOnly } from '@ember/object/computed';
import BasePolarisModal from '@smile-io/ember-smile-core/components/polaris-modals/base';
import { isNone, isBlank } from '@ember/utils';
import Changeset from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import pointsTransactionValidations from 'smile-admin/validations/points-transaction';

@classNames('polaris-modals-customer-adjust-points-balance-component')
export default class AdjustPointsBalance extends BasePolarisModal {
  @service
  alert;

  @service
  errorHandler;

  @service
  intl;

  @service
  sesh;

  @service
  store;

  pointsAdjustment = null;
  notifyCustomer = false;
  pointsTransactionValidations = pointsTransactionValidations;

  @readOnly('modalParams.customer')
  customer;

  @computed('pointsTransaction')
  get pointsTransactionChangeset() {
    let pointsTransaction = this.get('pointsTransaction');

    if (isNone(pointsTransaction)) {
      return null;
    }

    let pointsTransactionValidations = this.get('pointsTransactionValidations');

    return new Changeset(
      pointsTransaction,
      lookupValidator(pointsTransactionValidations),
      pointsTransactionValidations,
      { skipValidate: true }
    );
  }

  @(computed(
    'customer.points_balance',
    'pointsTransactionChangeset.points_change'
  ).readOnly())
  get newBalance() {
    let balance = parseInt(this.get('customer.points_balance'));
    let pointsChange = parseInt(
      this.get('pointsTransactionChangeset.points_change')
    );

    if (!isNaN(pointsChange)) {
      balance += pointsChange;
    }

    return balance;
  }

  applyPointsAdjustment = task(async (changeset) => {
    await changeset.validate();

    if (changeset.get('isInvalid')) {
      return;
    }

    try {
      if (isBlank(changeset.get('comment'))) {
        changeset.set('comment', this.intl.t('points_transaction.comment'));
      }

      await changeset.save();

      // TODO: need to reload customer points activity table here if it's visible
      await this.get('customer').reload();

      this.alert.info('Points adjusted');
    } catch (err) {
      this.errorHandler.handle(err);
    } finally {
      this.send('closeModal');
    }
  });

  init() {
    super.init(...arguments);

    let pointsTransactionProps = {
      customer: this.get('customer'),
      account_id: this.sesh.get('account.id'),
    };

    this.set(
      'pointsTransaction',
      this.get('store').createRecord(
        'points-transaction',
        pointsTransactionProps
      )
    );
  }

  willDestroyElement() {
    let pointsTransaction = this.get('pointsTransaction');
    if (pointsTransaction.get('isNew')) {
      pointsTransaction.rollbackAttributes();
    }

    super.willDestroyElement(...arguments);
  }

  @action
  notifyCustomerChanged([notifyCustomer]) {
    this.set('notifyCustomer', notifyCustomer);
  }
}
