/* import __COLOCATED_TEMPLATE__ from './promote-integrations.hbs'; */
import Component from '@glimmer/component';
import { service } from '@ember/service';

export default class PromoteIntegrationsComponent extends Component {
  @service shopify;

  get shouldRender() {
    return !this.shopify.isEmbedded;
  }
}
