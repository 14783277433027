/* import __COLOCATED_TEMPLATE__ from './banner-images.hbs'; */
import { action } from '@ember/object';
import { tagName } from '@ember-decorators/component';
import { service } from '@ember/service';
import Component from '@ember/component';

@tagName('')
export default class BannerImages extends Component {
  @service
  ajax;

  @service
  config;

  @service
  errorHandler;

  @service
  features;

  @service
  sesh;

  /**
   * Action fired when a property is updated.
   *
   * @property {onChange}
   * @type {Function}
   * @default no-op
   * @public
   */
  onChange() {}

  @action
  handleBannerDrop(panelHeaderChangeset, { iconUrl, iconS3Key }) {
    this.onChange(
      'panelHeader',
      panelHeaderChangeset,
      'bannerImageUrl',
      iconUrl
    );
    this.onChange(
      'panelHeader',
      panelHeaderChangeset,
      'bannerImageTempS3Key',
      iconS3Key
    );
  }

  @action
  handleBrandIconDrop(panelHeaderChangeset, { iconUrl, iconS3Key }) {
    this.onChange('panelHeader', panelHeaderChangeset, 'iconUrl', iconUrl);
    this.onChange(
      'panelHeader',
      panelHeaderChangeset,
      'iconTempS3Key',
      iconS3Key
    );
  }

  @action
  removeBrandIcon(panelHeaderChangeset) {
    this.onChange('panelHeader', panelHeaderChangeset, 'iconUrl', null);
    this.onChange('panelHeader', panelHeaderChangeset, 'iconTempS3Key', null);
  }

  @action
  removeBannerImage(panelHeaderChangeset) {
    this.onChange('panelHeader', panelHeaderChangeset, 'bannerImageUrl', null);
    this.onChange(
      'panelHeader',
      panelHeaderChangeset,
      'bannerImageTempS3Key',
      null
    );
  }

  @action
  handleError(err) {
    this.errorHandler.handle(err);
  }
}
