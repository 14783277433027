/* import __COLOCATED_TEMPLATE__ from './view-offer.hbs'; */
import WidePolarisModal from '@smile-io/ember-smile-core/components/polaris-modals/wide-modal';
import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import { task } from 'ember-concurrency';
import { service } from '@ember/service';

export default class ViewOffer extends WidePolarisModal {
  classNames = ['billing-subscription-view-offer-modal'];

  @service sesh;

  /**
   * When true, show the merchant extra Shopify-specific confirmation step.
   * @private
   */
  showExtraShopifyConfirmation = false;

  @readOnly('modalParams.isLoading')
  isLoading;

  @readOnly('modalParams.billingSubscription')
  billingSubscription;

  @readOnly('modalParams.onDecline')
  onDecline;

  @readOnly('modalParams.onContactSales')
  onContactSales;

  @computed(
    'billingSubscription.{isStarter,isGrowth,isPro,isStarterWithOrderLimit,isGrowthWithOrderLimit,isPlus}'
  )
  get shouldUseNewOfferModal() {
    let {
      billingSubscription: {
        isStarter,
        isGrowth,
        isPro,
        isStarterWithOrderLimit,
        isGrowthWithOrderLimit,
        isPlus,
      },
    } = this;

    return (
      isStarter ||
      isGrowth ||
      isPro ||
      isStarterWithOrderLimit ||
      isGrowthWithOrderLimit ||
      isPlus
    );
  }

  @computed(
    'isLoading',
    'sesh.account.hasShopifyPreferredBillingProvider',
    'subscriptionTemplate.slug'
  )
  get showShopifyRedirectNote() {
    return (
      !this.isLoading &&
      this.sesh.account.hasShopifyPreferredBillingProvider &&
      !this.modalParams.billingSubscription.isLegacyGrowth
    );
  }

  get isOfferForLegacySubscription() {
    return !(
      this.billingSubscription.isStarterWithOrderLimit ||
      this.billingSubscription.isGrowthWithOrderLimit ||
      this.billingSubscription.isPlus
    );
  }

  activateSubscription = task({ drop: true }, async () => {
    if (
      this.billingSubscription.isShopifyType &&
      !this.showExtraShopifyConfirmation &&
      this.modalParams.billingSubscription.isLegacyGrowth
    ) {
      this.set('showExtraShopifyConfirmation', true);
      return;
    }

    try {
      await this.get('onConfirm')();
    } finally {
      this.send('closeModal');
    }
  });

  declineBillingSubscription = task({ drop: true }, async () => {
    try {
      await this.get('onDecline')();
    } finally {
      this.send('closeModal');
    }
  });
}
