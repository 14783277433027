/* import __COLOCATED_TEMPLATE__ from './app-nav-content.hbs'; */
import { tagName } from '@ember-decorators/component';
import { service } from '@ember/service';
import Component from '@ember/component';

@tagName('')
export default class AppNavContent extends Component {
  @service
  media;

  @service
  sesh;

  @service
  featureRollouts;
}
