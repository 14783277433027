/* import __COLOCATED_TEMPLATE__ from './feedback-bar.hbs'; */
import Component from '@ember/component';
import { task } from 'ember-concurrency';
import { resolve } from 'rsvp';
import { tagName } from '@ember-decorators/component';

@tagName('')
export default class FeedbackBarComponent extends Component {
  /**
   * Callback when an option in the bottom bar is selected
   * @type {Function}
   * @public
   */
  onSelectOption = resolve;

  isOptionSelected = false;

  get barOptions() {
    return [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ];
  }

  selectOption = task({ drop: true }, async (optionValue) => {
    await this.onSelectOption(optionValue);
    this.set('isOptionSelected', true);
  });
}
