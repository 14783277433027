import { hash, array } from '@ember/helper';
import ResponsiveImage from '@responsive-image/ember/components/responsive-image';
import Button from '@smile-io/ember-shopify-polaris/components/button';
import ExternalRedirect from '../../helpers/external-redirect';
import HomeCard from '../smile/home-card';
import shopifyWinterEditionImage from '../../images/home/shopify-winter-edition-feature.jpg?w=500;1000&format=original;webp;avif&lqip=inline&responsive';

<template>
  <div>
    <HomeCard
      @alignment="center"
      @title="Featured in Shopify 2024 Winter Edition"
      @text="We’ve added checkout extensibility using the latest features of Shopify Plus."
      @media={{hash size="extra-large"}}
      @buttons={{array
        (component
          Button
          text="Watch the video"
          onClick=(ExternalRedirect
            "https://www.youtube.com/watch?v=pW1L3iKNpA0&t=51s"
          )
        )
      }}
    >
      <:media>
        <ResponsiveImage
          loading="eager"
          fetchPriority="high"
          @src={{shopifyWinterEditionImage}}
          @height={{300}}
        />
      </:media>
    </HomeCard>
  </div>
</template>
