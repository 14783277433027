import Service, { service } from '@ember/service';
import { compare, isPresent, isNone } from '@ember/utils';
import { timeout, waitForProperty, task } from 'ember-concurrency';
import { findChangesetItemForType } from 'smile-admin/utils/find-changeset-item-for-type';
import setPreviewAttributes from 'smile-admin/utils/smile-ui/set-preview-attributes';
import { isValidColor } from 'smile-admin/utils/color';
import { loadScript } from 'smile-admin/utils/load-script';
import { getCustomizablePropertyValue } from 'smile-admin/utils/customizable-property';

const PREVIEW_UPDATE_INTERVAL_MS = 300;

// Super simple retry logic for loading/initializing SmileUI
// Retry INIT_RETRIES_COUNT times waiting INIT_RETRY_TIMEOUT_MS between retries (30 secs)
const INIT_RETRIES_COUNT = 5;
const INIT_RETRY_TIMEOUT_MS = 200;

export default class SmileUiService extends Service {
  @service config;
  @service sesh;

  smileUIInstance = null;

  /**
   * @private
   */
  previewCustomer = null;

  /**
   * Used to avoid redundant preview route updates if the preview route hasn't changed
   *
   * @type {String}
   * @private
   */
  currentPreviewRoute = null;

  /**
   * Used to avoid redundant customer data updates if the customer data hasn't changed
   *
   * @type {String}
   * @private
   */
  currentCustomerData = null;

  /**
   * Used to avoid redundant preview data override updates if the override data hasn't changed
   *
   * @type {String}
   * @private
   */
  currentPreviewDataOverridesJson = '';

  initialize = task(
    { restartable: true },
    async ({
      previewType,
      previewRoute,
      isPreviewCustomer,
      previewDataOverrides = {},
    }) => {
      // Wait until the Smile UI script is loaded (only loaded once)
      await this.loadSmileUI();

      let { sesh, smileUIInstance, previewCustomer } = this;

      // We fetch the preview customer w/o wondering if we'll need it for init
      // because we're going to need preview customer eventually when we use
      // this service.
      // A majority of panel previews require it & only panel
      // workflow's visitor section doesn't need it.
      if (!previewCustomer) {
        previewCustomer = await sesh.account.getPreviewCustomer({
          include: 'image_svg',
        });
        previewCustomer = previewCustomer.customer_preview;
      }

      let initialCustomerData = isPreviewCustomer ? previewCustomer : undefined;

      let { key } = sesh.platformIntegration.salesChannels.find(
        (channel) => channel.type === 'online_store'
      );

      let initObject = {
        channel_key: key,
        previewData: {
          previewType,
          initialCustomerData,
          previewRoute,
          noCache: true,
          ...previewDataOverrides,
        },
      };

      // `SmileUI` here is the global script
      let shouldRetry = INIT_RETRIES_COUNT;
      while (shouldRetry && !smileUIInstance) {
        try {
          smileUIInstance = await SmileUI.init(initObject);
        } catch (err) {
          // eslint-ignore no-empty
        }

        shouldRetry -= 1;
        await timeout(INIT_RETRY_TIMEOUT_MS);
      }

      await smileUIInstance.ready();

      this.setProperties({
        smileUIInstance,
        previewCustomer,
        currentPreviewRoute: previewRoute,
        currentCustomerData: initialCustomerData,
        currentPreviewDataOverridesJson: JSON.stringify(previewDataOverrides),
      });

      return smileUIInstance;
    }
  );

  updatePreview = task(
    { restartable: true },
    async ({
      changes,
      changesets,
      isMobile,
      isVisitor,
      previewRoute,
      previewDataOverrides,
    }) => {
      /**
       * Debounce because:
       *
       * - SmileUI isn't currently optimised for updates;
       * - Each theme colour update results in several requests
       *   being made to the API to get recoloured SVGs. The
       *   colour picker we use triggers many updates when its
       *   sliders get dragged around, which results in us
       *   hitting the API hard with SVG requests.
       *
       * TODO: consider switching to throttling instead of debouncing.
       */
      await timeout(PREVIEW_UPDATE_INTERVAL_MS);

      // Wait for initialization to complete & smileUIInstance to be available before proceeding.
      await waitForProperty(this, 'smileUIInstance', (instance) =>
        isPresent(instance)
      );

      let data = this.generatePreviewData(changesets);
      if (data.display_settings) {
        this.smileUIInstance.updatePreviewData(
          'set-preview-display-settings-data',
          { ...data.display_settings, isMobile }
        );
      }

      if (data.launcher) {
        this.smileUIInstance.updatePreviewData('set-preview-launcher-data', {
          ...data.launcher,
          isMobile,
        });
      }

      if (data.panel) {
        this.smileUIInstance.updatePreviewData(
          'set-preview-panel-data',
          data.panel
        );
      }

      if (data.nudge) {
        let [nudgeChanges] = changes;

        data.nudge.title = nudgeChanges.title;
        data.nudge.subtitle = nudgeChanges.subtitle;
        data.nudge.cta = nudgeChanges.cta;
        data.nudge.delivery_type = nudgeChanges.deliveryType;
        data.nudge.icon_url = nudgeChanges.iconUrl;

        this.smileUIInstance.updatePreviewData(
          'set-preview-nudge-data',
          data.nudge
        );
      }

      this._updatePreviewCustomerData(
        isVisitor ? undefined : this.previewCustomer
      );
      this._updatePreviewPath(previewRoute);
      this._updatePreviewDataOverrides(previewDataOverrides);
    }
  );

  generatePreviewData(changesetItems) {
    // Get model data along with any changed data
    let launcher = this.extractChangesetData(
      findChangesetItemForType(changesetItems, 'launcher')
    );
    let panel = this.extractChangesetData(
      findChangesetItemForType(changesetItems, 'panel')
    );
    let panelHeader = this.extractChangesetData(
      findChangesetItemForType(changesetItems, 'panel-header')
    );
    let displaySettings = this.extractChangesetData(
      findChangesetItemForType(changesetItems, 'display-setting')
    );
    let nudge = this.extractChangesetData(
      findChangesetItemForType(changesetItems, 'nudge')
    );

    // Grab colors attributes - these are the only attributes
    // that will affect models other than the model they're
    // saved to (display-setting)
    let primaryHex;
    let secondaryHex;
    if (displaySettings) {
      primaryHex = displaySettings.primaryColor;
      secondaryHex = displaySettings.secondaryColor;
    }

    let data = {
      nudge: setPreviewAttributes(nudge, primaryHex, secondaryHex),
      launcher: setPreviewAttributes(launcher, primaryHex, secondaryHex),
      panel: setPreviewAttributes(panel, primaryHex, secondaryHex),
      display_settings: setPreviewAttributes(
        displaySettings,
        primaryHex,
        secondaryHex
      ),
    };

    if (panelHeader) {
      data.panel = data.panel || {};
      data.panel.panel_header = setPreviewAttributes(
        panelHeader,
        primaryHex,
        secondaryHex
      );
    }

    if (launcher) {
      data.launcher = {
        ...data.launcher,
        ...this.transformLauncherIconField(launcher),
      };
    }

    return data;
  }

  /**
   * Merges the data & changes from the changeset w/o relying on changeset's API since
   * we need this only for the purpose of previewing those changes real time.
   */
  extractChangesetData(changeset) {
    if (isNone(changeset)) {
      return null;
    }

    let changes = this.preventInvalidColors(changeset.get('change'));
    let underlyingModel = changeset.get('data').content;
    let original = underlyingModel.getProperties(
      ...underlyingModel.constructor.attributes.keys()
    );

    const requiredPropertiesStartingWithCustomizedOrDefault = [
      // We need these two properties in the final data hash
      // so that transformLauncherIconField can do its thing.
      'defaultIconUrl',
      'customizedIconUrl',
    ];
    return Object.keys(original).reduce((updated, key) => {
      // Handle customizable properties here.
      // Ignore properties starting with `default` and `customized`,
      // but there are some exceptions that we still need to process.
      if (
        (key.indexOf('customized') === 0 || key.indexOf('default') === 0) &&
        requiredPropertiesStartingWithCustomizedOrDefault.indexOf(key) === -1
      ) {
        return updated;
      }

      let value = getCustomizablePropertyValue(changes, original, key);

      return {
        ...updated,
        [key]: value,
      };
    }, {});
  }

  /**
   * Prevent sending of invalid hex values to smile-ui as the user is typing. If
   * the updated value is invalid, it will get removed from the change object.
   */
  preventInvalidColors(changes) {
    let newPrimary = changes.customizablePrimaryColor;
    let newSecondary = changes.customizableSecondaryColor;

    if (newPrimary && !isValidColor(newPrimary)) {
      delete changes.customizablePrimaryColor;
    }

    if (newSecondary && !isValidColor(newSecondary)) {
      delete changes.customizableSecondaryColor;
    }

    return changes;
  }

  transformLauncherIconField(launcher) {
    let { customizedUsesDefaultIcon, usesDefaultIcon } = launcher;
    usesDefaultIcon =
      typeof customizedUsesDefaultIcon === 'boolean'
        ? customizedUsesDefaultIcon
        : usesDefaultIcon;

    if (usesDefaultIcon) {
      return { icon_url: launcher.defaultIconUrl };
    } else {
      return {
        icon_url: launcher.customizedIconUrl || launcher.defaultIconUrl,
      };
    }
  }

  _updatePreviewCustomerData(customer) {
    if (compare(this.currentCustomerData, customer) === 0) {
      return;
    }

    if (isPresent(customer)) {
      this.smileUIInstance.updatePreviewData('set-preview-customer-data', {
        customer,
      });
    } else {
      this.smileUIInstance.updatePreviewData('remove-preview-customer-data');
    }

    this.set('currentCustomerData', customer);
  }

  _updatePreviewDataOverrides(previewDataOverrides) {
    if (
      compare(
        this.currentPreviewDataOverridesJson,
        JSON.stringify(previewDataOverrides)
      ) === 0
    ) {
      return;
    }

    if (isPresent(previewDataOverrides)) {
      this.smileUIInstance.updatePreviewData('set-preview-data-overrides', {
        previewDataOverrides,
      });
    }

    this.set(
      'currentPreviewDataOverridesJson',
      JSON.stringify(previewDataOverrides)
    );
  }

  _updatePreviewPath(pathToVisit) {
    if (this.currentPreviewRoute === pathToVisit) {
      return;
    }

    this.smileUIInstance.updatePreviewData('set-preview-panel-route', {
      route: pathToVisit,
    });
    this.set('currentPreviewRoute', pathToVisit);
  }

  loadSmileUI() {
    return loadScript(`${this.config.get('smileUI.host')}/v1/smile-ui.js`);
  }

  destroy() {
    this.initialize.cancelAll();
    this.updatePreview.cancelAll();

    if (!this.smileUIInstance) {
      return;
    }

    this.smileUIInstance.destroy();
    this.set('smileUIInstance', null);
  }
}
