import { validatePresence } from 'ember-changeset-validations/validators';
import validateSometimes from 'smile-admin/validators/sometimes';

export default {
  webdavUsername: validateSometimes(
    validatePresence({
      presence: true,
      description: 'WebDAV username',
    }),
    function () {
      return !this.get('isStencilEnabled');
    }
  ),

  webdavPassword: validateSometimes(
    validatePresence({
      presence: true,
      description: 'WebDAV password',
    }),
    function () {
      return !this.get('isStencilEnabled');
    }
  ),
};
