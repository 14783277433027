<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" ...attributes>
    <path
      d="M8.75 1.25c0 .69.559 1.25 1.25 1.25 4.136 0 7.5 3.364 7.5 7.5s-3.364 7.5-7.5 7.5a1.25 1.25 0 1 0 0 2.5c5.514 0 10-4.486 10-10S15.514 0 10 0C9.31 0 8.75.56 8.75 1.25z"
      fill="#637381"
    />
    <path
      d="m7.134 5.366 3.75 3.75a1.249 1.249 0 0 1 0 1.768l-3.75 3.75a1.246 1.246 0 0 1-1.768 0 1.248 1.248 0 0 1 0-1.768l1.616-1.616H1.25a1.25 1.25 0 1 1 0-2.5h5.732L5.366 7.134a1.249 1.249 0 1 1 1.768-1.768z"
      fill="#637381"
    />
  </svg>
</template>
