/* import __COLOCATED_TEMPLATE__ from './referral-share-link.hbs'; */
import { tagName } from '@ember-decorators/component';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { gt } from '@ember/object/computed';
import Component from '@ember/component';
import EmberObject, { action, computed } from '@ember/object';
import { isNone } from '@ember/utils';
import { storageFor } from 'ember-local-storage';
import moment from 'moment';
import { types as trackableTaskTypes } from 'smile-admin/models/trackable-task';
import { types as notificationTypes } from 'smile-admin/models/customer-notification';
import { query } from 'smile-admin/utils/ember-data/builders';

const referralsDashboardRoute = 'reward-programs.referrals.dashboard';

@tagName('')
export default class ReferralShareLink extends Component {
  @service router;
  @service sesh;
  @service store;

  referralLinksTrackableTask = null;

  @storageFor('sweet-tooth-session')
  localStorage;

  @gt('sesh.account.members_count', 1)
  hasRequiredNoOfMembers;

  @computed(
    'localStorage.hasDismissedSendReferralLinksCard',
    'hasRequiredNoOfMembers'
  )
  get shouldRender() {
    return (
      !this.localStorage.get('hasDismissedSendReferralLinksCard') &&
      this.hasRequiredNoOfMembers
    );
  }

  startReferralLinksTrackableTask = task(
    {
      drop: true,
    },
    async () => {
      let referralsProgram = this.sesh.referralsRewardProgram;
      let currentRoute = this.router.currentRouteName;
      let referralLinksTrackableTask =
        await referralsProgram.sendReferralSharingLinks();

      if (currentRoute === referralsDashboardRoute) {
        this.set('referralLinksTrackableTask', referralLinksTrackableTask);
      } else {
        this.router.transitionTo(referralsDashboardRoute);
      }
    }
  );

  fetchReferralLinksTrackableTask = task(
    {
      drop: true,
    },
    async () => {
      let { store, sesh } = this;
      let type = trackableTaskTypes.referralSharingLinkEmail;

      // Check store first - task may have already been loaded on
      // the home route if user is seeing this card on the referrals
      // dashboard route.
      let referralLinksTasks = store.peekAll('trackable-task');
      let referralLinksTrackableTask = referralLinksTasks.find(
        (task) => task.type === type
      );

      if (referralLinksTrackableTask) {
        return referralLinksTrackableTask;
      }

      referralLinksTasks = (
        await store.request(
          query('trackable-task', {
            account_id: sesh.account.id,
            type,
          })
        )
      ).content;

      return referralLinksTasks[0];
    }
  );

  checkReferralLinksTrackableTask = task(
    {
      drop: true,
    },
    async () => {
      let { fetchReferralLinkNotification, fetchReferralLinksTrackableTask } =
        this;
      let referralLinksTrackableTask =
        await fetchReferralLinksTrackableTask.perform();

      if (isNone(referralLinksTrackableTask)) {
        return fetchReferralLinkNotification.perform();
      }

      let now = moment();
      let taskFinished = referralLinksTrackableTask.finishedAt;
      let taskCompletedInHoursAgo = taskFinished
        ? now.diff(taskFinished, 'hours')
        : 0;

      if (taskCompletedInHoursAgo >= 24) {
        this.send('dismissCard');
      } else {
        this.set('referralLinksTrackableTask', referralLinksTrackableTask);
      }
    }
  );

  fetchReferralLinkNotification = task(
    {
      drop: true,
    },
    async () => {
      let { content: notifications } = await this.store.request(
        query('customer-notification', {
          type: notificationTypes.referralSharingLink,
          include: 'preview_data,email_html_template',
        })
      );
      let notification = notifications[0];
      let previewData = notification.previewData || EmberObject.create({});
      let templateVariables = Object.keys(previewData);

      this.setProperties({
        referralLinkNotification: notification,
        notificationTemplateVariables: templateVariables,
        notificationPreviewData: previewData,
      });
    }
  );

  init() {
    super.init(...arguments);

    if (this.shouldRender) {
      this.checkReferralLinksTrackableTask.perform();
    }
  }

  @action
  dismissCard() {
    let dismiss = this.onDismiss;

    if (dismiss) {
      dismiss('hasDismissedSendReferralLinksCard');
    }
  }
}
