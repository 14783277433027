import Model, { hasMany, belongsTo, attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { service } from '@ember/service';

export default Model.extend({
  sesh: service(),

  title: attr('string'),
  handle: attr('string'),
  price: attr('number'),

  image: belongsTo('shopify-product-image', { async: false, inverse: null }),
  variants: hasMany('shopify-product-variant', {
    async: false,
    inverse: 'product',
  }),

  isExcluded: attr('boolean'),

  // URL to the product variant on the store frontend.
  urlForStore: computed(function () {
    return `${this.get('sesh.account.url')}/products/${this.get('handle')}`;
  }).readOnly(),
});
