/* import __COLOCATED_TEMPLATE__ from './features-list.hbs'; */
import Component from '@glimmer/component';
import { service } from '@ember/service';

export default class NewBillingPlanFeaturesListComponent extends Component {
  @service sesh;

  get heading() {
    if (this.args.withoutHeading) {
      return false;
    }

    if (
      this.args.newBillingPlan.billingProduct.isStarterWithOrderLimit ||
      this.args.newBillingPlan.billingProduct.isGrowthWithOrderLimit
    ) {
      return 'Take customer engagement to another level!';
    } else if (this.args.newBillingPlan.billingProduct.isPlus) {
      return 'Get the best of Smile with more customization and the data your team needs.';
    }

    return null;
  }

  get features() {
    if (this.args.newBillingPlan.billingProduct.isStarterWithOrderLimit) {
      return [
        { name: 'Custom graphics' },
        { name: 'Customizable messaging' },
        { name: 'Remove Smile branding' },
        { name: 'Advanced analytics' },
        {
          name: 'Nudges',
          tooltipText:
            'Nudges are small pop-ups on your site that remind and encourage customers to engage with your program.',
          isNew: true,
        },
        {
          name: 'Connect Smile to 2 apps',
          tooltipText:
            'Smile integrations allow you to combine your rewards program with other apps you use to supercharge your customer experience (e.g. emails, reviews, customer service, etc.)',
          isNew: true,
        },
      ];
    } else if (this.args.newBillingPlan.billingProduct.isGrowthWithOrderLimit) {
      let features = [
        {
          name: 'VIP program',
          tooltipText:
            'The VIP program allows you to segment your customers into different tiers, creating a unique experience for your most loyal shoppers (i.e. higher points on purchases, larger birthday rewards, and exclusive perks).',
          isNew: true,
        },
        { name: 'Points expiration' },
        { name: 'Product rewards' },
        {
          name: 'Connect Smile to unlimited apps',
          tooltipText:
            'Smile integrations allow you to combine your rewards program with other apps you use to supercharge your customer experience (e.g. emails, reviews, customer service, etc.)',
          isNew: true,
        },
      ];
      if (this.sesh.hasShopifyPlatform) {
        const shopifyFeatures = [
          {
            name: 'Points redemption at checkout (Shopify Plus)',
            isNew: true,
          },
          {
            name: 'Points widget on product page & customer account',
            isNew: true,
          },
          {
            name: 'Loyalty Landing Page',
            isNew: true,
          },
        ];
        features = shopifyFeatures.concat(features);
      }
      return features;
    } else if (this.args.newBillingPlan.billingProduct.isPlus) {
      return [
        { name: 'Powerful reporting tools' },
        { name: 'Priority support' },
        { name: 'API access' },
        { name: 'Preferred pricing for multi-store' },
      ];
    }

    return [];
  }
}
