import { array } from '@ember/helper';
import ResponsiveImage from '@responsive-image/ember/components/responsive-image';
import Button from '@smile-io/ember-shopify-polaris/components/button';
import Icon from '@smile-io/ember-shopify-polaris/components/icon';
import ExternalIcon from '@smile-io/polaris-icons/components/external-icon';
import ExternalRedirect from '../../helpers/external-redirect';
import HomeCard from '../smile/home-card';
import kbImage from '../../images/home/kb.png?w=360&format=original;webp;avif&lqip=inline&responsive';

<template>
  <div>
    <HomeCard
      @alignment="center"
      @title="Have a question? Check out our Help Center!"
      @text="Our help center has everything you need to get the most out of your Smile program."
      @buttons={{array
        (component
          Button
          text="Visit the Help Center"
          icon=(component Icon source=ExternalIcon)
          onClick=(ExternalRedirect "https://help.smile.io")
        )
      }}
    >
      <:media>
        <ResponsiveImage @src={{kbImage}} @height={{120}} />
      </:media>
    </HomeCard>
  </div>
</template>
