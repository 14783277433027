/* import __COLOCATED_TEMPLATE__ from './choose-interaction-medium.hbs'; */
import WidePolarisModal from '@smile-io/ember-smile-core/components/polaris-modals/wide-modal';
import { readOnly, and } from '@ember/object/computed';
import { task } from 'ember-concurrency';
import { storageFor } from 'ember-local-storage';
import { service } from '@ember/service';
import { interactionMediums } from 'smile-admin/models/display-setting';
import { query } from 'smile-admin/utils/ember-data/builders';

export default class ChooseInteractionMediumDisplaySettingModalComponent extends WidePolarisModal {
  classNames = ['choose-interaction-medium-display-setting-modal'];

  @service errorHandler;
  @service sesh;
  @service store;

  interactionMediums = interactionMediums;

  @storageFor('sweet-tooth-session')
  localStorage;

  @readOnly('fetchDisplaySetting.lastSuccessful.value')
  displaySetting;

  @and(
    'chooseInteractionMedium.isRunning',
    'displaySetting.hasPromptsInteractionMedium'
  )
  isPromptsChoiceBeingSelected;

  @and(
    'chooseInteractionMedium.isRunning',
    'displaySetting.hasPanelInteractionMedium'
  )
  isPanelChoiceBeingSelected;

  chooseInteractionMedium = task({ drop: true }, async (medium) => {
    try {
      let { displaySetting } = this;
      displaySetting.set('interactionMedium', medium);

      await displaySetting.save();

      // Reset session-storage flag
      this.localStorage.set(
        'showChooseDisplayInteractionMediumPostOnboarding',
        false
      );

      this.onCloseModal();
    } catch (err) {
      this.errorHandler.handle(err);
    }
  });

  fetchDisplaySetting = task({ drop: true }, async () => {
    try {
      let { content: displaySettings } = await this.store.request(
        query('display-setting', {
          account_id: this.sesh.account.id,
        })
      );

      return displaySettings[0];
    } catch (err) {
      this.errorHandler.handle(err);
    }
  });

  init() {
    super.init(...arguments);
    this.fetchDisplaySetting.perform();
  }
}
