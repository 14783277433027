// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import BaseStore from 'ember-data/store';
import { service } from '@ember/service';
import { deprecate } from '@ember/debug';

const deprecateOldApi = (method) =>
  deprecate(
    `Use of 'store.${method}' has been deprecated. Please use 'store.request' instead.`,
    false,
    {
      id: 'smile-admin.deprecate-old-ember-data-api',
      until: '5.0.0',
      url: 'https://github.com/smile-io/smile-admin/pull/7255',
      for: 'smile-admin',
      since: {
        available: '5.0.0',
        enabled: '5.0.0',
      },
    }
  );

export default class Store extends BaseStore {
  @service requestManager;

  findRecord() {
    deprecateOldApi('findRecord');
    return super.findRecord(...arguments);
  }

  findAll() {
    deprecateOldApi('findAll');
    return super.findAll(...arguments);
  }

  query() {
    deprecateOldApi('query');
    return super.query(...arguments);
  }

  queryRecord() {
    deprecateOldApi('queryRecord');
    return super.queryRecord(...arguments);
  }
}
