import Controller from '@ember/controller';
import { readOnly } from '@ember/object/computed';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { storageFor } from 'ember-local-storage';
import { action } from '@ember/object';

export default class ApplicationController extends Controller {
  @service config;
  @service intercom;
  @service media;
  @service router;
  @service sesh;
  @service shopify;
  @service stickyManager;

  queryParams = [{ gtm_debug: { scope: 'controller' } }];

  @tracked isTopBarVisible = false;
  @tracked isNavEnabled = false;

  @storageFor('sweet-tooth-session') localStorage;

  @readOnly('media.isMobile') isNavLocked;

  @action
  disableNav() {
    // When embedded in Shopify, we never use the navigation menu
    if (this.shopify.isEmbedded) {
      return;
    }

    this.isNavEnabled = false;
  }

  @action
  enableNav() {
    // When embedded in Shopify, we never use the navigation menu
    if (this.shopify.isEmbedded) {
      return;
    }

    this.isNavEnabled = true;
  }

  @action
  showCustomer(customer) {
    this.router.transitionTo('customers.show', customer.id);
  }

  @action
  setStickyContainer() {
    // We need to set the container element for `polaris-sticky`
    // to the right element, otherwise `polaris-sticky` instances
    // won't seem to do anything. This is because the sticky manager
    // attaches its scroll event handler to the container element,
    // so if that isn't the element that's scrolling, the scroll
    // handler won't fire.
    let container = document.querySelector('.bm-content');
    this.stickyManager.setContainer(container);
  }

  @action
  trackAppUpdate(/* currentAppVersion, newAppVersion */) {}

  @action
  trackAppUpdateDismissal(/* currentAppVersion, newAppVersion */) {}

  get homeRoute() {
    if (this.sesh.account.lockedPendingPayment) {
      return 'restricted-access';
    } else if (this.sesh.account.isInOrderLimitUpgradePeriod) {
      return 'restricted-access-over-limit';
    }

    return 'home';
  }
}
