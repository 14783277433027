/* import __COLOCATED_TEMPLATE__ from './container.hbs'; */
import Component from '@glimmer/component';
import { service } from '@ember/service';
import BillingSubscriptionTemplateData from 'smile-admin/objects/billing-subscription-template-data';

export default class BillingSubscriptionTemplateContainer extends Component {
  @service sesh;

  get subscriptionTemplateData() {
    return new BillingSubscriptionTemplateData(
      this.args.billingSubscriptionTemplate,
      this.sesh.account
    );
  }
}
