import Helper from '@ember/component/helper';
import { service } from '@ember/service';

// Helper based on the official ember-feature-flags addon helper
// https://github.com/kategengler/ember-feature-flags/blob/756abe10fbfb392564dec4dac520b7733d342ba4/addon/helpers/feature-flag.js
export default class FeatureRolloutsFlagHelper extends Helper {
  @service featureRollouts;

  compute([flag]) {
    return this.featureRollouts.isEnabled(flag);
  }
}
