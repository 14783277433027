/* import __COLOCATED_TEMPLATE__ from './sync-shop-provider.hbs'; */
// TODO: Add ability to determine merchant's platform while using sync shop provider

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { isForbiddenError } from '@smile-io/ember-smile-core/adapters/errors';
import { arg } from '@smile-io/ember-smile-core/utils/decorators/arg';

export default class NewIntegrationSyncShopProviderComponent extends Component {
  @service errorHandler;
  @service sesh;
  @service utils;

  @arg
  refreshOnBrowserActiveIfHasMissingRequirements = true;

  get platformIntegration() {
    return this.sesh.platformIntegration;
  }

  get smileRequirements() {
    return {
      shopifyCustomerAccountsMissing:
        this.sesh.hasShopifyPlatform &&
        !this.platformIntegration.customerAccountsEnabled,
    };
  }

  get hasMissingSmileRequirements() {
    return this.smileRequirements.shopifyCustomerAccountsMissing;
  }

  get customerAccountsDisabled() {
    return this.smileRequirements.shopifyCustomerAccountsMissing;
  }

  syncShop = task({ drop: true }, async () => {
    try {
      await this.platformIntegration.syncShop();
    } catch (error) {
      // if 403, redirect the merchant to re-authorize Smile because we lost access due to a bug on Wix
      // This seems to not be happening anymore, but this is here to make sure we don't get this problem
      if (isForbiddenError(error)) {
        window.location.href = this.platformIntegration.oauthConnectUrl;
        return;
      }

      this.errorHandler.handle(error);
    }
  });

  constructor() {
    super(...arguments);
    this.syncShop.perform();
  }

  @action
  goToShopifyCustomerAccountsSettings() {
    this.utils.openExternalLink(
      `${this.sesh.account.url}/admin/settings/customer_accounts`
    );
  }
}
