import { service } from '@ember/service';
import { storageFor } from 'ember-local-storage';

import config from 'smile-admin/config/environment';

export default class AuthHandler {
  @service session;

  @storageFor('sweet-tooth-session')
  localStorage;

  request(context, next) {
    const headers = new Headers(context.request.headers);
    headers.append(
      'Authorization',
      `Bearer ${this.session.data.authenticated.authentication_token}`
    );

    headers.append('Smile-Client', config.modulePrefix);

    if (this.localStorage.get('isAdmin')) {
      headers.append('X-SMILE-IS-ADMIN', 'true');
    }

    return next({ ...context.request, headers });
  }
}
