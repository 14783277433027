import Model, { attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { service } from '@ember/service';

export default Model.extend({
  sesh: service(),

  name: attr('string'),
  custom_url: attr('string'),

  // Url to the product on the store frontend.
  productUrl: computed(function () {
    return `${this.get('sesh.account.url')}${this.get('custom_url')}`;
  }).readOnly(),
});
