/* import __COLOCATED_TEMPLATE__ from './home-card.hbs'; */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { equal } from '@ember/object/computed';
import { classify } from '@ember/string';
import { tagName } from '@ember-decorators/component';

export const alignmentOptions = {
  left: 'left',
  center: 'center',
};

export const mediaPositionOptions = {
  left: 'left',
  right: 'right',
};

export const mediaSizeOptions = {
  small: 'small',
  medium: 'medium',
  large: 'large',
};

/**
 * Presentational component to tie together the common
 * layout and functionality shared by all home cards.
 */
@tagName('')
export default class HomeCardComponent extends Component {
  /**
   * Controls horizontal alignment of card content.
   * Supported values are 'left' and 'center'.
   *
   * @type {String}
   * @default 'left'
   * @public
   */
  alignment = 'left';

  /**
   * Card title text.
   *
   * @type {String}
   * @default null
   * @public
   */
  title = null;

  /**
   * Main body text.
   *
   * @type {String|Component|ComponentDefinition}
   * @default null
   * @public
   */
  text = null;

  /**
   * Subdued, caption-sized text appearing below the main body text.
   *
   * @type {String|Component|ComponentDefinition}
   * @default null
   * @public
   */
  subtext = null;

  /**
   * Hash of parameters defining what media (image) to display on the card.
   * Available properties are:
   *
   *  imgSrc {String}
   *    Specifies an image to render using an img element.
   *    If null, no image will be rendered.
   *    Defaults to null.
   *
   *  position {String}
   *    Where to render the media content.
   *    Possible values are 'left' and 'right'.
   *    This property will have no effect when the card's `alignment` property is set to 'center'.
   *    Defaults to 'left'.
   *
   *  size {String}
   *    How large to render the media content.
   *    Can be 'small' or 'large'; 'small' will be rendered aligned with the top of the card,
   *    whilst medium/large media will be vertically centered.
   *    Defaults to 'small'.
   *
   * @type {Object}
   * @default null
   * @public
   */
  media = null;

  /**
   * List of hashes describing the buttons to render at the bottom of the card.
   * If omitted, no buttons will be rendered.
   * Each item in the array can have the same properties as supported by the `Button` component.
   *
   * @type {Array}
   * @default null
   * @public
   */
  buttons = null;

  /**
   * List of hashes describing the actions to display on a popup menu activated by
   * an ellipsis icon on the top-right of the card; this popup menu trigger will
   * not be rendered if no `popoverActions` are present.
   * Each item in the array can have the same properties as a `polaris-action-list` item.
   *
   * @type {Array}
   * @default null
   * @public
   */
  popoverActions = null;

  /**
   * Hash of properties controlling the card's behaviour around collecting merchant feedback;
   * no feedback gathering components will be rendered if this is `null`.
   * Available options are:
   *
   *  onSubmit	{Function}
   *    Action invoked when feedback is submitted.
   *    Defaults to no-op.
   *
   * @type {Object}
   * @default null
   * @public
   */
  feedbackOptions = null;

  @equal('alignment', alignmentOptions.center)
  isCenterAligned;

  @equal('alignment', alignmentOptions.left)
  isLeftAligned;

  @computed('alignmentClass')
  get classList() {
    return ['smile-home-card', this.alignmentClass].join(' ');
  }

  @computed('alignment')
  get alignmentClass() {
    return `smile-home-card--alignment${classify(this.alignment)}`;
  }

  @computed()
  get shouldRenderMediaBeforeContent() {
    // Render media before content if card is centered or media is positioned on the left.
    let mediaPosition = this.media?.position ?? mediaPositionOptions.left;
    return this.isCenterAligned || mediaPosition === mediaPositionOptions.left;
  }
}
