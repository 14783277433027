import { findMatchingImage } from '@responsive-image/core';
import '/app/node_modules/.embroider/rewritten-app/images/home/kb.png.css!=!@responsive-image/webpack/lqip/inline-css!/app/node_modules/.embroider/rewritten-app/images/home/kb.png?className=ri-dyn-0&targetPixels=60';
const images = [{"url":__webpack_public_path__ + "images/kb-360w-7e0562ad99684e02.png","width":360,"format":"png"},{"url":__webpack_public_path__ + "images/kb-360w-230a96e7e279bb7e.webp","width":360,"format":"webp"},{"url":__webpack_public_path__ + "images/kb-360w-507a5fd7416c86f1.avif","width":360,"format":"avif"}];
export default {
    imageTypes: ["png","webp","avif"],
    availableWidths: [360],
    lqip: {"type":"inline","class":"ri-dyn-0"},
    aspectRatio: 1.5,
    imageUrlFor(w, f) {
      return findMatchingImage(images, w, f ?? "png")?.url;
    }
  }