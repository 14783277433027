import Controller from '@ember/controller';
import { computed } from '@ember/object';
import { service } from '@ember/service';

export default Controller.extend({
  config: service(),
  sesh: service(),

  pointsExportSecureUrlEndpoint: computed('sesh.account.id', function () {
    return `${this.get('config.apiHost')}/v1/accounts/${this.get(
      'sesh.account.id'
    )}/export_points_balances/download_url`;
  }),
});
