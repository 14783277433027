import MultiChangesetsItemComponent from 'smile-admin/components/multi-changesets-item';
import { schedule } from '@ember/runloop';
import { guidFor } from '@ember/object/internals';
import { createCustomizableProxy } from 'smile-admin/helpers/create-customizable-proxy';
import { task } from 'ember-concurrency';
import Changeset from 'ember-changeset';
import { isChangeset } from 'validated-changeset';
import isObject from 'ember-changeset/utils/is-object';
import lookupValidator from 'ember-changeset-validations';

export default class MultiChangesetsItemCustomizableProxyComponent extends MultiChangesetsItemComponent {
  findChangesetForObject(modelGuid) {
    return this.changesets.items.find((item) => {
      return (
        guidFor(item.get('content')) === modelGuid ||
        guidFor(item.get('content')?.get('content')) === modelGuid
      );
    });
  }

  setChangeset(changeset) {
    this.set('changeset', changeset);
    this.get('onChangesetInitialised')(changeset);
  }

  createChangeset = task(async () => {
    let model = this.object;
    let validationMap = this.validationMap;
    let modelGuid = guidFor(model);
    let changesetAlreadyAdded =
      this.changesets.changesetModelGuidsAdded.includes(modelGuid);
    let changeset;

    if (changesetAlreadyAdded) {
      schedule('afterRender', this, function () {
        this.setChangeset(this.findChangesetForObject(modelGuid));
      });

      return;
    }

    if (isChangeset(model)) {
      changeset = model;
    } else {
      let object = await model;

      object = createCustomizableProxy(object);

      if (isObject(validationMap)) {
        changeset = new Changeset(
          object,
          lookupValidator(validationMap),
          validationMap,
          { skipValidate: this.skipValidate }
        );
      } else {
        changeset = new Changeset(object, validationMap);
      }

      if (!this.changesets.changesetModelGuidsAdded.includes(modelGuid)) {
        this.changesets.changesetModelGuidsAdded.push(modelGuid);
      }
    }

    this.changesets.registerChangeset(changeset);

    this.setChangeset(changeset);
  });

  // noop on `willDestroyElement` because the parent
  // component unregisters the changeset on de-render,
  // and we don't want that for smile-ui forms.
  // This should be fine because the parent class is a
  // `Component`, which uses a noop for it's
  // `willDestroyElement` hook.
  // eslint-disable-next-line ember/require-super-in-lifecycle-hooks
  willDestroyElement() {}
}
