import Controller from '@ember/controller';
import { readOnly } from '@ember/object/computed';
import { service } from '@ember/service';

export default class ErrorController extends Controller {
  @service config;

  @readOnly('config.statusPage.url')
  statusPageUrl;
}
