/* import __COLOCATED_TEMPLATE__ from './stripe-checkout-provider.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import { reads } from '@ember/object/computed';
import Component from '@ember/component';

/**
 * Handles activating a subscription with Stripe Checkout.
 * NOTE: Only one of these components should exist in the entire app
 * at the same time.
 */

export default class StripeCheckoutProvider extends Component {
  @service
  billing;

  @service
  sesh;

  /**
   * Used to manually trigger showing the Stripe Checkout modal
   *
   * @type {Boolean}
   * @private
   */
  @reads('billing.showStripeCheckout')
  showStripeCheckout;

  /**
   * Flag set by the billing service to indicate that the merchant is in the process
   * of updating their Stripe credit card payment.
   *
   * @type {Boolean}
   * @private
   */
  @reads('billing.isUpdatingStripePayment')
  isUpdatingStripePayment;

  /**
   * Billing subscription that backs the Stripe Checkout modal, when the merchant doesn't
   * have billing info and wants to upgrade/accept an offer with Stripe provider.
   *
   * @type {DS.Model}
   * @private
   */
  @reads('billing.activateableStripeSubscription')
  activateableStripeSubscription;

  @action
  processStripeToken(token) {
    this.billing.processStripeToken(token);
  }
}
