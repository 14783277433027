/* import __COLOCATED_TEMPLATE__ from './visibility.hbs'; */
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { action } from '@ember/object';
import { isArray } from '@ember/array';
import { interactionMediums } from 'smile-admin/models/display-setting';
import { visibilityOptions } from 'smile-admin/models/launcher';

export default class SmileUiVisibilityComponent extends Component {
  @service sesh;
  @service featureRollouts;

  interactionMediums = interactionMediums;
  visibilityOptions = visibilityOptions;

  get isOnFreePlan() {
    return this.sesh.account.chargeableSubscription.isFree;
  }

  get reducedFreeBrandingVisibilitySetting() {
    const { launcherChangeset } = this.args;
    if (!launcherChangeset) {
      return null;
    }

    // Use the changed customized value if present, otherwise use
    // the value provided by the API since this includes free plan
    // lockdowns etc.
    return launcherChangeset.change.customizableVisibilitySetting
      ? launcherChangeset.get('customizableVisibilitySetting')
      : launcherChangeset.get('visibilitySetting');
  }

  @action visibilityUpdated(newVisibility) {
    newVisibility = isArray(newVisibility) ? newVisibility[0] : newVisibility;

    this.args.onChange(
      'launcher',
      this.args.launcherChangeset,
      'customizableVisibilitySetting',
      newVisibility
    );
  }

  @action setMobileVisibility(isHiddenOnMobile) {
    let newVisibility = isHiddenOnMobile
      ? visibilityOptions.desktop
      : visibilityOptions.desktopAndMobile;

    this.args.onChange(
      'launcher',
      this.args.launcherChangeset,
      'customizableVisibilitySetting',
      newVisibility
    );
  }

  @action updateHiddenUrlPaths(hiddenUrlPaths) {
    let updatedValue = hiddenUrlPaths.filter((x) => !!x);

    // Changeset requires us to create a new array & then set the property to refer to the new array
    // https://github.com/poteto/ember-changeset/issues/138#issuecomment-264666351
    this.args.onChange(
      'launcher',
      this.args.launcherChangeset,
      'customizableHiddenUrlPaths',
      updatedValue
    );
  }

  @action handleInteractionMediumChange(
    displaySettingChangeset,
    [interactionMedium]
  ) {
    // If the merchant switches back to prompts without saving, we reset back
    // the visibility setting in case the merchant played with it while having
    // panel selected.
    if (interactionMedium === this.interactionMediums.prompts) {
      this.args.launcherChangeset.rollbackProperty(
        'customizableVisibilitySetting'
      );
    }

    this.args.onChange(
      'displaySetting',
      displaySettingChangeset,
      'interactionMedium',
      interactionMedium
    );
  }
}
