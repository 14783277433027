import { findMatchingImage } from '@responsive-image/core';
import '/app/node_modules/.embroider/rewritten-app/images/home/shopify-winter-edition-feature.jpg.css!=!@responsive-image/webpack/lqip/inline-css!/app/node_modules/.embroider/rewritten-app/images/home/shopify-winter-edition-feature.jpg?className=ri-dyn-1&targetPixels=60';
const images = [{"url":__webpack_public_path__ + "images/shopify-winter-edition-feature-500w-c8f5ed47c61ab0f0.jpg","width":500,"format":"jpeg"},{"url":__webpack_public_path__ + "images/shopify-winter-edition-feature-500w-e26e5bddcb522c9f.webp","width":500,"format":"webp"},{"url":__webpack_public_path__ + "images/shopify-winter-edition-feature-500w-a9948f05a93d2a60.avif","width":500,"format":"avif"},{"url":__webpack_public_path__ + "images/shopify-winter-edition-feature-1000w-23984f4520391f8d.jpg","width":1000,"format":"jpeg"},{"url":__webpack_public_path__ + "images/shopify-winter-edition-feature-1000w-9963173139b6782f.webp","width":1000,"format":"webp"},{"url":__webpack_public_path__ + "images/shopify-winter-edition-feature-1000w-8b6dee53fb701e15.avif","width":1000,"format":"avif"}];
export default {
    imageTypes: ["jpeg","webp","avif"],
    availableWidths: [500,1000],
    lqip: {"type":"inline","class":"ri-dyn-1"},
    aspectRatio: 1.55,
    imageUrlFor(w, f) {
      return findMatchingImage(images, w, f ?? "jpeg")?.url;
    }
  }