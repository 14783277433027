/* import __COLOCATED_TEMPLATE__ from './guest-referrals-disabled-notice.hbs'; */
import Component from '@ember/component';
import { tagName } from '@ember-decorators/component';
import { action } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import { service } from '@ember/service';

@tagName('')
export default class GuestReferralsDisabledNoticeComponent extends Component {
  @service sesh;

  @readOnly('sesh.account.showGuestReferralsDisabledNotice')
  shouldRender;

  @action
  dismissCard() {
    let { account } = this.sesh;
    account.set('showGuestReferralsDisabledNotice', false);
    account.save();
  }
}
