export const REPORT_IDS = {
  financialValueOfDiscountsIssuedOverTime:
    'financial_value_of_discounts_issued_over_time',
  financialValueOfOutstandingPoints: 'financial_value_of_outstanding_points',
  firstTimeVsRepeatEarnersOverTime: 'first_time_vs_repeat_earners_over_time',
  firstTimeVsRepeatRedeemersOverTime:
    'first_time_vs_repeat_redeemers_over_time',
  listOfAllCustomers: 'list_of_all_customers',
  listOfAllCustomersByVipTier: 'list_of_all_customers_by_vip_tier',
  listOfAllCustomersWhoCanRedeem: 'list_of_all_customers_who_can_redeem',
  listOfAllDiscountsCreatedBySmile: 'list_of_all_discounts_created_by_smile',
  listOfAllPointsRedemptions: 'list_of_all_points_redemptions',
  listOfAllPointsTransactions: 'list_of_all_points_transactions',
  listOfAllReferrals: 'list_of_all_referrals',
  listOfAllSmileInfluencedOrders: 'list_of_all_smile_influenced_orders',
  listOfAllVipTierChanges: 'list_of_all_vip_tier_changes',
  listOfOrdersPlacedByReferredCustomers:
    'list_of_orders_placed_by_referred_customers',
  orderEarningRateOverTime: 'order_earning_rate_over_time',
  outstandingPointsOverTime: 'outstanding_points_over_time',
  performanceMembers: 'performance_members',
  performancePoints: 'performance_points',
  performanceReferrals: 'performance_referrals',
  pointsActivityOverTime: 'points_activity_over_time',
  redemptionRateOverTime: 'redemption_rate_over_time',
  referralConversionRateOverTime: 'referral_conversion_rate_over_time',
  referralTrafficOverTime: 'referral_traffic_over_time',
  rewardUsageRateOverTime: 'reward_usage_rate_over_time',
  salesFromReferredCustomersOverTime: 'sales_from_referred_customers_over_time',
  salesInfluencedBySmileOverTime: 'sales_influenced_by_smile_over_time',
  smileBenchmarks: 'smile_benchmarks',
  topReferrers: 'top_referrers',
  topWaysToEarn: 'top_ways_to_earn',
  topWaysToRedeem: 'top_ways_to_redeem',
  totalMembersOverTime: 'total_members_over_time',
  vipTierChangesOverTime: 'vip_tier_changes_over_time',
  vipTierMembersOverTime: 'vip_tier_members_over_time',
};

const STARTER_REPORTS_FLAG = 'starter_reports';
const GROWTH_REPORTS_FLAG = 'growth_reports';
// const PLUS_REPORTS_FLAG = 'plus_reports';

// List of reports defined in this notion doc
// https://www.notion.so/smileteam/Smile-reports-5181eefd98c843909d5f4ca8c985a1ed
export const REPORTS = [
  // Attribution category
  {
    name: 'Sales influenced by Smile over time',
    key: REPORT_IDS.salesInfluencedBySmileOverTime,
    category: 'Sales attribution',
    requiredFeatureFlag: STARTER_REPORTS_FLAG,
  },

  // Industry benchmarks category
  {
    name: 'Smile benchmarks',
    key: REPORT_IDS.smileBenchmarks,
    category: 'Benchmarks',
    requiredFeatureFlag: GROWTH_REPORTS_FLAG,
  },

  // Points category
  {
    name: 'Points activity over time',
    key: REPORT_IDS.pointsActivityOverTime,
    category: 'Points',
    requiredFeatureFlag: STARTER_REPORTS_FLAG,
  },
  {
    name: 'First-time vs repeat earners',
    key: REPORT_IDS.firstTimeVsRepeatEarnersOverTime,
    category: 'Points',
    requiredFeatureFlag: STARTER_REPORTS_FLAG,
  },
  {
    name: 'First-time vs repeat redeemers',
    key: REPORT_IDS.firstTimeVsRepeatRedeemersOverTime,
    category: 'Points',
    requiredFeatureFlag: STARTER_REPORTS_FLAG,
  },
  {
    name: 'Top ways customers earn',
    key: REPORT_IDS.topWaysToEarn,
    category: 'Points',
    requiredFeatureFlag: STARTER_REPORTS_FLAG,
  },
  {
    name: 'Top ways customers redeem',
    key: REPORT_IDS.topWaysToRedeem,
    category: 'Points',
    requiredFeatureFlag: STARTER_REPORTS_FLAG,
  },
  {
    name: 'Outstanding points over time',
    key: REPORT_IDS.outstandingPointsOverTime,
    category: 'Points',
    requiredFeatureFlag: STARTER_REPORTS_FLAG,
  },
  {
    name: 'Total members over time',
    key: REPORT_IDS.totalMembersOverTime,
    category: 'Points',
    requiredFeatureFlag: STARTER_REPORTS_FLAG,
  },
  {
    name: 'Redemption rate over time',
    key: REPORT_IDS.redemptionRateOverTime,
    category: 'Points',
    requiredFeatureFlag: STARTER_REPORTS_FLAG,
  },
  {
    name: 'Reward usage rate over time',
    key: REPORT_IDS.rewardUsageRateOverTime,
    category: 'Points',
    requiredFeatureFlag: STARTER_REPORTS_FLAG,
  },
  {
    name: 'Order earning rate over time',
    key: REPORT_IDS.orderEarningRateOverTime,
    category: 'Points',
    requiredFeatureFlag: STARTER_REPORTS_FLAG,
  },

  // Financial value category
  {
    name: 'Financial value of outstanding points',
    key: REPORT_IDS.financialValueOfOutstandingPoints,
    category: 'Finance',
    requiredFeatureFlag: GROWTH_REPORTS_FLAG,
  },
  {
    name: 'Financial value of discounts issued over time',
    key: REPORT_IDS.financialValueOfDiscountsIssuedOverTime,
    category: 'Finance',
    requiredFeatureFlag: GROWTH_REPORTS_FLAG,
  },

  // Referrals category
  {
    name: 'Sales from referred customers over time',
    key: REPORT_IDS.salesFromReferredCustomersOverTime,
    category: 'Referrals',
    requiredFeatureFlag: STARTER_REPORTS_FLAG,
  },
  {
    name: 'Referral traffic over time',
    key: REPORT_IDS.referralTrafficOverTime,
    category: 'Referrals',
    requiredFeatureFlag: STARTER_REPORTS_FLAG,
  },
  {
    name: 'Referral conversion rate over time',
    key: REPORT_IDS.referralConversionRateOverTime,
    category: 'Referrals',
    requiredFeatureFlag: STARTER_REPORTS_FLAG,
  },
  {
    name: 'Top referrers',
    key: REPORT_IDS.topReferrers,
    category: 'Referrals',
    requiredFeatureFlag: STARTER_REPORTS_FLAG,
  },

  // VIP category
  {
    name: 'VIP tier changes over time',
    key: REPORT_IDS.vipTierChangesOverTime,
    category: 'VIP',
    requiredFeatureFlag: GROWTH_REPORTS_FLAG,
  },
  {
    name: 'VIP tier members over time',
    key: REPORT_IDS.vipTierMembersOverTime,
    category: 'VIP',
    requiredFeatureFlag: GROWTH_REPORTS_FLAG,
  },

  // Data export category
  {
    name: 'List of Smile influenced orders',
    key: REPORT_IDS.listOfAllSmileInfluencedOrders,
    category: 'Data export',
  },
  {
    name: 'List of discounts created by Smile',
    key: REPORT_IDS.listOfAllDiscountsCreatedBySmile,
    category: 'Data export',
  },
  {
    name: 'List of points transactions',
    key: REPORT_IDS.listOfAllPointsTransactions,
    category: 'Data export',
  },
  {
    name: 'List of customers',
    key: REPORT_IDS.listOfAllCustomers,
    category: 'Data export',
  },
  {
    name: 'List of customers who can redeem',
    key: REPORT_IDS.listOfAllCustomersWhoCanRedeem,
    category: 'Data export',
  },
  {
    name: 'List of customers by VIP tier',
    key: REPORT_IDS.listOfAllCustomersByVipTier,
    category: 'Data export',
  },
  {
    name: 'List of points redemptions',
    key: REPORT_IDS.listOfAllPointsRedemptions,
    category: 'Data export',
  },
  {
    name: 'List of referrals',
    key: REPORT_IDS.listOfAllReferrals,
    category: 'Data export',
  },
  {
    name: 'List of orders placed by referred customers',
    key: REPORT_IDS.listOfOrdersPlacedByReferredCustomers,
    category: 'Data export',
  },
  {
    name: 'List of VIP tier changes',
    key: REPORT_IDS.listOfAllVipTierChanges,
    category: 'Data export',
  },
];
