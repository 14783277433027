/* import __COLOCATED_TEMPLATE__ from './media.hbs'; */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { classify } from '@ember/string';
import { isBlank } from '@ember/utils';
import { tagName } from '@ember-decorators/component';
import {
  mediaSizeOptions,
  mediaPositionOptions,
} from 'smile-admin/components/smile/home-card';

@tagName('')
export default class MediaComponent extends Component {
  /**
   * Hash of media options passed down from the parent component.
   *
   * @type {Object}
   * @default null
   * @public
   */
  media = null;

  /**
   * Alignment specified for the parent card.
   *
   * @type {String}
   * @default null
   * @public
   */
  cardAlignment = null;

  /**
   * String containing the size for the media, falling back to the default if none is specified.
   *
   * @type {String}
   * @private
   */
  @computed('media.size')
  get size() {
    return this.media?.size ?? mediaSizeOptions.small;
  }

  /**
   * String containing the position for the media, falling back to the default if none is specified.
   *
   * @type {String}
   * @private
   */
  @computed('media.position')
  get position() {
    return this.media?.position ?? mediaPositionOptions.left;
  }

  @computed('size')
  get sizeClass() {
    return `smile-home-card-media--size${classify(this.size)}`;
  }

  @computed('position')
  get positionClass() {
    return `smile-home-card-media--position${classify(this.position)}`;
  }

  @computed('cardAlignment')
  get cardAlignmentClass() {
    let { cardAlignment } = this;
    if (isBlank(cardAlignment)) {
      return null;
    }

    return `smile-home-card-media--alignment${classify(cardAlignment)}`;
  }
}
