/* eslint-disable ember/no-new-mixins */
import Mixin from '@ember/object/mixin';
import { isEmpty } from '@ember/utils';
import { service } from '@ember/service';
import { hash } from 'rsvp';
import { query } from 'smile-admin/utils/ember-data/builders';

export default Mixin.create({
  sesh: service(),
  store: service(),

  modelForExport: null,
  accountExportType: null,
  queryParams: {},

  model() {
    let superModelPromise = this._super(...arguments);

    let modelForExportPromise = this.store.request(
      query(this.get('modelForExport'), {
        account_id: this.get('sesh.account.id'),
        page_size: 1,
        page: 1,
        ...this.queryParams,
      })
    );

    let latestAccountExportsPromise = this.store.request(
      query('account-export', {
        type: this.get('accountExportType'),
        include: 'trackable_task',
        page_size: 1,
        page: 1,
      })
    );

    return hash({
      modelForExport: modelForExportPromise,
      latestAccountExports: latestAccountExportsPromise,
      superModel: superModelPromise,
    }).then(({ modelForExport, latestAccountExports, superModel }) => ({
      modelForExport: modelForExport.content,
      latestAccountExports: latestAccountExports.content,
      superModel,
    }));
  },

  setupController(controller, model) {
    this._super(controller, model.superModel);

    let latestAccountExport = model.latestAccountExports[0];
    let latestExportTrackableTask =
      latestAccountExport && latestAccountExport.get('trackableTask');

    controller.setProperties({
      accountExportType: this.accountExportType,
      showEmptyState: isEmpty(model.modelForExport),
      latestAccountExport: latestAccountExport,
      showExportProgress:
        latestExportTrackableTask &&
        !latestExportTrackableTask.get('isFinished'),
    });
  },
});
