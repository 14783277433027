/* import __COLOCATED_TEMPLATE__ from './search.hbs'; */
import Component from '@ember/component';
import { tagName } from '@ember-decorators/component';
import { action, computed } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { next } from '@ember/runloop';
import { timeout, task } from 'ember-concurrency';
import { resultsItemTypes } from 'smile-admin/utils/global-search';

const SEARCH_DEBOUNCE_INTERVAL_MS = 400;

@tagName('')
export default class AppTopBarSearch extends Component {
  searchFieldText = '';
  searchTerm = '';
  customers = [];
  page = 1;
  pageSize = 10;
  hasLoadedItems = false;
  hasMoreItems = null;
  showAdjustBalanceModal = false;
  customer = null;

  @computed('customers.[]', 'hasMoreItems')
  get resultItems() {
    let { customers } = this;

    if (isEmpty(customers)) {
      return [
        {
          type: this.hasLoadedItems
            ? resultsItemTypes.noItems
            : resultsItemTypes.initialLoad,
        },
      ];
    }

    let resultItems = customers.map((customer) => ({
      type: resultsItemTypes.customer,
      model: customer,
    }));

    if (this.hasMoreItems) {
      resultItems.push({
        type: resultsItemTypes.loadMoreItems,
      });
    }

    return resultItems;
  }

  focusSearchField() {
    document.querySelector('.app-top-bar-search input').focus();
  }

  handleDebouncedSearchTermChange = task(
    { restartable: true },
    async (loader, popover, newSearchTerm) => {
      this.set('searchFieldText', newSearchTerm);
      popover.open();

      await timeout(SEARCH_DEBOUNCE_INTERVAL_MS);
      this.setProperties({
        searchTerm: newSearchTerm,
        page: 1,
      });

      next(() => this.loadResults.perform(loader));
    }
  );

  loadResults = task(
    { restartable: true },
    async (loader, { appendResults = false } = {}) => {
      let customers = await loader.reload();
      let hasMoreItems = customers?.length === this.pageSize;

      if (appendResults) {
        customers = [...this.customers, ...customers];
      }

      this.setProperties({
        customers,
        hasMoreItems,
        hasLoadedItems: true,
      });
    }
  );

  @action
  showResults(popover) {
    if (this.searchFieldText) {
      popover.open();
    }
  }

  @action
  handleLoadMoreResults(loader) {
    this.incrementProperty('page');
    next(() => this.loadResults.perform(loader, { appendResults: true }));

    this.focusSearchField();
  }

  @action
  adjustCustomerBalance(customer) {
    this.set('customer', customer);
    this.set('showAdjustBalanceModal', true);
  }
}
