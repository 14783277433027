import BillingSubscriptionData from 'smile-admin/objects/billing-subscription-data';
import { billingFormatPrice } from 'smile-admin/helpers/billing/format-price';
import { pluralize } from 'ember-inflector';
import moment from 'moment';
import numeral from 'numeral';
import {
  discountTypes as couponDiscountTypes,
  durations as couponDurations,
} from 'smile-admin/models/billing-coupon';

const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export default class PlanDetailsCardData {
  constructor(account, routerService) {
    this.account = account;
    this.chargeableSubscription = account.chargeableSubscription;
    this.subscriptionData = new BillingSubscriptionData(
      this.chargeableSubscription
    );

    this.router = routerService;
  }

  get accountOrderLimit() {
    return this.account.accountOrderLimit;
  }

  get currentMonth() {
    const date = new Date();
    return MONTH_NAMES[date.getMonth()];
  }

  get previousMonth() {
    const date = new Date();
    const currentMonthIndex = date.getMonth();
    return MONTH_NAMES[(currentMonthIndex - 1 + 12) % 12];
  }

  get planName() {
    return this.chargeableSubscription.friendlyBasePlanName;
  }

  get planSlug() {
    return this.chargeableSubscription.basePlanSlug;
  }

  get planOrderLimit() {
    const basePlanUsageAllowance =
      this.chargeableSubscription.basePlanUsageAllowance;
    return basePlanUsageAllowance?.monthlyOrders;
  }

  get basePlanPrice() {
    return this.chargeableSubscription.basePlan.amountCents;
  }

  get billingPriceTotal() {
    return this.subscriptionData.priceTotal;
  }

  get currentMonthOrders() {
    return this.accountOrderLimit?.totalBillableOrdersCurrentMonth || 0;
  }

  get previousMonthOrders() {
    return this.accountOrderLimit?.totalBillableOrdersPreviousMonth || 0;
  }

  get projectedMonthOrders() {
    return (
      this.accountOrderLimit?.totalProjectedBillableOrdersCurrentMonth || 0
    );
  }

  get monthlyOrdersCredits() {
    return this.account.monthlyOrdersCredits;
  }

  get isFirstSevenDays() {
    return this.account.daysSinceSignup <= 7;
  }

  get isFirstCalendarMonth() {
    return moment(this.account.created_at).isSame(moment(), 'month');
  }

  get isOverOrderLimitCurrentMonth() {
    return this.account.isOverOrderLimitCurrentMonth;
  }

  get secondMonthOverLimit() {
    return this.account.isInOrderLimitGracePeriodNew;
  }

  get hasOrdersAddon() {
    return !!this.chargeableSubscription.ordersAddOnNewBillingPlan;
  }

  get currentMonthOrdersExemptedByPricingProtection() {
    return this.accountOrderLimit
      ?.totalOrdersExemptedByPricingProtectionCurrentMonth;
  }

  get currentMonthOrdersWithoutEmail() {
    return this.accountOrderLimit?.totalOrdersWithNoEmailCurrentMonth;
  }

  get previousMonthOrdersWithoutEmail() {
    return this.accountOrderLimit?.totalOrdersWithNoEmailPreviousMonth;
  }

  get previousMonthOrdersExemptedByPricingProtection() {
    return this.accountOrderLimit
      ?.totalOrdersExemptedByPricingProtectionPreviousMonth;
  }

  get nextBilledAt() {
    return this.chargeableSubscription.nextBilledAt;
  }

  get ordersAllowancePrice() {
    if (!this.hasOrdersAddon) {
      return 0;
    }

    return this.chargeableSubscription.ordersAddOnNewBillingPlan.amountCents;
  }

  get monthlyOrdersAllowance() {
    if (!this.hasOrdersAddon) {
      return 0;
    }

    const ordersAllowance =
      this.chargeableSubscription.ordersAddOnNewBillingPlan
        .billingPlanUsageAllowance;

    return ordersAllowance?.monthlyOrders || 0;
  }

  get discountAmountCents() {
    return this.subscriptionData.discountAmountCents;
  }

  get discountDescription() {
    if (!this.subscriptionData.billingDiscount) {
      return '';
    }

    const {
      billingCoupon: { discountType, percentOff, duration },
      endsAt,
    } = this.subscriptionData.billingDiscount;

    let description = '';

    if (discountType === couponDiscountTypes.fixedAmount) {
      description = `Your plan has ${billingFormatPrice(this.discountAmountCents)} off`;
    } else {
      const subscriptionText = this.chargeableSubscription.isShopifyType
        ? 'plan subscription price'
        : 'plan';
      description = `Your ${subscriptionText} has a ${numeral(percentOff).format('0,0[.]00')}% discount applied `;
    }

    if (endsAt && endsAt.isValid()) {
      description = `${description} until ${moment(endsAt).format('MMMM DD, YYYY')}`;
    } else {
      if (duration === couponDurations.once) {
        description = `${description} once`;
      } else if (duration === couponDurations.forever) {
        description = `${description} forever`;
      } else if (duration === couponDurations.repeating) {
        description = `${description} for ${pluralize(
          this.subscriptionData.friendlyDiscountDurationCount,
          this.subscriptionData.basePlan.interval
        )}`;
      }
    }

    return description;
  }

  onChangePlanClick = () => {
    this.router.transitionTo('settings.billing.pricing-plans');
  };

  //Legacy plan methods
  get isLegacyPlan() {
    return (
      !this.chargeableSubscription.isFree &&
      !this.chargeableSubscription.isStarterWithOrderLimit &&
      !this.chargeableSubscription.isGrowthWithOrderLimit &&
      !this.chargeableSubscription.isPlus
    );
  }

  get members() {
    return this.account.members_count || 0;
  }

  get hasMembersAddon() {
    return !!this.chargeableSubscription.membersAddOnNewBillingPlan;
  }

  get planMembersLimit() {
    const basePlanUsageAllowance =
      this.chargeableSubscription.basePlanUsageAllowance;
    return (
      (this.account.membersCredits || 0) +
      (basePlanUsageAllowance.programMembersCount || 0)
    );
  }

  get membersAllowancePrice() {
    if (!this.hasMembersAddon) {
      return 0;
    }

    return this.chargeableSubscription.membersAddOnNewBillingPlan.amountCents;
  }

  get membersAllowance() {
    if (!this.hasMembersAddon) {
      return 0;
    }

    const membersAllowance =
      this.chargeableSubscription.membersAddOnNewBillingPlan
        .billingPlanUsageAllowance;

    return membersAllowance?.programMembersCount || 0;
  }

  get planInterval() {
    return this.chargeableSubscription.basePlan.interval;
  }

  get isBillingShopifyType() {
    return this.chargeableSubscription.isShopifyType;
  }

  get billingDiscount() {
    return this.subscriptionData.billingDiscount;
  }
}
