import Model, { hasMany, belongsTo, attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { memberAction } from 'ember-api-actions';
import { serializeAndPush } from 'smile-admin/utils/models/serialize-and-push';

export const types = {
  bigcommerce: 'bigcommerce_new_integration',
  bronto: 'bronto_new_integration',
  dotmailer: 'dotmailer_new_integration',
  hubspot: 'hubspot_new_integration',
  klaviyo: 'klaviyo_new_integration',
  mailchimp: 'mailchimp_new_integration',
  recharge: 'recharge_new_integration',
  shopify: 'shopify_new_integration',
  yotpo: 'yotpo_new_integration',
};

export const shopifyCustomerAccountVersions = {
  classic: 'CLASSIC',
  new: 'NEW_CUSTOMER_ACCOUNTS',
};

export default Model.extend({
  type: attr('string'),
  dataJson: attr('object'),
  apiToken: attr('string'),
  isEnabled: attr('boolean'),
  iconUrl: attr('string'),
  displayName: attr('string'),
  shortDescription: attr('string'),
  oauthConnectUrl: attr('string'),
  authenticationUrl: attr('string'),

  // API keys
  apiKey: attr('string'),
  apiSecret: attr('string'),

  // Platform app fields
  isPlatformApp: attr('boolean'),
  appReviewUrl: attr('string'),
  orderTotalRewardValueIncludeTaxes: attr('boolean'),
  orderTotalRewardValueIncludeShipping: attr('boolean'),

  // Available on Shopify
  customerAccountsEnabled: attr('boolean'),
  customerAccountsVersion: attr('string'),

  // Fields for Shopify platform app
  orderAwardStatuses: attr('comma-separated-array'),
  orderCancelStatuses: attr('comma-separated-array'),
  orderTotalRewardValueIncludeDiscounts: attr('boolean'),
  isShopifyPlus: attr('boolean'),
  canUseCustomerTags: attr('boolean'),
  excludedCustomerTags: attr('array'),
  uiExtensionCollectionInstallationBaseUrl: attr('string'), // this requires a call to the Shopify API, and as such is opt-in.
  themeSettingsAppEmbedDeepLink: attr('string'),
  pointsOnAccountPageInstallationUrl: attr('string'),
  pointsOnProductPageInstallationUrl: attr('string'),

  // Fields for BigCommerce platform app
  orderAwardStatusIds: attr('comma-separated-array'),
  orderCancelStatusIds: attr('comma-separated-array'),
  orderTotalRewardValueIncludeHandling: attr('boolean'),
  orderTotalRewardValueIncludeWrapping: attr('boolean'),
  orderTotalRewardValueIncludeCoupons: attr('boolean'),
  orderTotalRewardValueIncludeStoreCredit: attr('boolean'),
  orderTotalRewardValueIncludeGiftCertificates: attr('boolean'),
  orderTotalRewardValueIncludeDiscountRules: attr('boolean'),
  hasAddedCodeSnippetToSite: attr('boolean'),
  webdavUsername: attr('string'),
  webdavPassword: attr('string'),
  isStencilEnabled: attr('boolean'),
  adminUrl: attr('string'),
  rewardableSalesChannels: attr('string'),
  totalOrders28dBeforeInstall: attr('number'),

  // Fields for private platform app
  customerSignupUrl: attr('string'),
  customerLoginUrl: attr('string'),
  externalCustomerUrlTemplate: attr('string'),

  createdAt: attr('date'),

  account: belongsTo('account', { async: false, inverse: 'newIntegrations' }),
  app: belongsTo('app', { async: false, inverse: 'newIntegrations' }),
  connectedAccount: belongsTo('connected-account', {
    async: false,
    inverse: null,
  }),
  customerIdentityOrigin: belongsTo('customer-identity-origin', {
    async: false,
    inverse: null,
  }),
  lastInitialSyncTask: belongsTo('trackable-task', {
    async: false,
    inverse: null,
  }),
  oauth2AccessToken: belongsTo('oauth2-access-token', {
    async: false,
    inverse: 'newIntegration',
  }),
  webhooks: hasMany('webhook', { async: false, inverse: 'newIntegration' }),
  rewardDefinitionListings: hasMany('reward-definition-listing', {
    async: false,
    inverse: null,
  }),
  activityRuleDefinitionListings: hasMany('activity-rule-definition-listing', {
    async: false,
    inverse: null,
  }),
  salesChannels: hasMany('sales-channel', { async: false, inverse: null }),
  activityRules: hasMany('activity-rule', {
    async: false,
    inverse: null,
  }),
  rewards: hasMany('reward', { async: false, inverse: null }),
  // Relationships for Shopify platform app.
  lastExcludeCustomersByTagsTask: belongsTo('trackable-task', {
    async: false,
    inverse: null,
  }),

  // Helper CPs for platform Apps
  supportsDefaultOrderConditions: computed('type', function () {
    return [
      'shopify_new_integration',
      'bigcommerce_new_integration',
      'salesforce_commerce_cloud_new_integration',
    ].includes(this.get('type'));
  }).readOnly(),

  supportsDefaultOrderTotals: computed('type', function () {
    return [
      'shopify_new_integration',
      'bigcommerce_new_integration',
      'salesforce_commerce_cloud_new_integration',
    ].includes(this.get('type'));
  }).readOnly(),

  supportsMergedOrderEarningRules: computed('type', 'account', function () {
    return (
      this.type === 'shopify_new_integration' &&
      this.account.usesMergedOrderActivityRules &&
      this.rewardableSalesChannels
    );
  }),

  onlineStoreSalesChannel: computed('salesChannels.@each.type', function () {
    return this.get('salesChannels').find(
      (channel) => channel.isOnlineStoreType
    );
  }).readOnly(),

  isConnected: computed(
    'app.isThirdParty',
    'oauth2AccessToken.shouldReauthenticate',
    'connectedAccount.isConnectionUnhealthy',
    function () {
      return (
        (this.app.isThirdParty &&
          !this.oauth2AccessToken?.shouldReauthenticate) ||
        Boolean(this.connectedAccount?.isConnectionHealthy)
      );
    }
  ).readOnly(),

  uiExtensionCollectionInstallationUrl: computed(
    'uiExtensionCollectionInstallationBaseUrl',
    function () {
      if (!this.uiExtensionCollectionInstallationBaseUrl) return '';
      return `${this.uiExtensionCollectionInstallationBaseUrl}&collection=extensions-collection`;
    }
  ).readOnly(),

  createConnectedAccount: memberAction({
    path: 'create_connected_account',
    type: 'post',
    after: serializeAndPush,
  }),
  excludeCustomersByTags: memberAction({
    path: 'exclude_customers_by_tags',
    type: 'put',
    after: serializeAndPush,
  }),
  startCustomerImport: memberAction({
    path: 'start_customer_import',
    type: 'post',
    after: serializeAndPush,
  }),
  syncShop: memberAction({
    path: 'sync_shop',
    type: 'put',
    after: serializeAndPush,
  }),
  startInitialSync: memberAction({
    path: 'initial_sync',
    type: 'post',
    after: serializeAndPush,
  }),
});
