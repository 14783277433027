import Helper from '@ember/component/helper';
import { service } from '@ember/service';

export default class ExternalRedirect extends Helper {
  @service utils;

  compute([link], { newTab, replace }) {
    return () => this.utils.openExternalLink(link, { newTab, replace });
  }
}
