import BaseStore from 'ember-simple-auth/session-stores/base';
import { service } from '@ember/service';
import { getOwner } from '@ember/application';

/**
 * By default, `ember-simple-auth` uses the adaptive session store which will use local-storage, if available, cookie storage otherwise.
 * We overwrite that behavior to support both standalone & embedded Smile on Shopify:
 *  - standalone Shopify  - uses the `adaptive` session storage
 *  - embedded Shopify    - uses an `ephemeral` session storage (in-memory)
 *
 * Insipred by the `adaptive` ember-simple-auth session-store.
 */
export default class ApplicationSessionStore extends BaseStore {
  @service shopify;

  init() {
    super.init(...arguments);

    let owner = getOwner(this);
    const store = this.shopify.isEmbedded
      ? owner.lookup('session-store:ephemeral')
      : owner.lookup('session-store:adaptive');

    this.set('_store', store);
    this._setupStoreEvents(store);
  }

  _setupStoreEvents(store) {
    store.on('sessionDataUpdated', (data) => {
      this.trigger('sessionDataUpdated', data);
    });

    return store;
  }

  persist() {
    return this.get('_store').persist(...arguments);
  }

  restore() {
    return this.get('_store').restore();
  }

  clear() {
    return this.get('_store').clear();
  }
}
